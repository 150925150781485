import React from 'react';

const NotFound = () => {
  return (
      <div className="row">
      <div className="d-flex justify-content-center page-not-found-wrapper">
        <img
          src="/images/404.svg"
          height="550"
          width="550"
          alt="404_not_found"
        />
      </div>
      <h5 className="text-center">
        Aradığınız sayfa bulunamadı <a href="/">Anasayfa</a>
      </h5>
    </div>
  );
};

export default NotFound;