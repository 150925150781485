import React from 'react';
import { Link } from 'react-router-dom';

const CheckOutSteps = ({ shipping, confirmOrder, payment }) => {

  return (
     <div className="checkout-progress d-flex justify-content-center mt-5 row">
     
      {shipping ? (
        <Link
          to="/shipping"
          className="float-right mt-2 mt-md-0 col-12 col-md-3 col-lg-2"
        >
          <div className="triangle2-active"></div>
          <div className="step active-step">Teslimat</div>
          <div className="triangle-active"></div>
        </Link>
      ) : (
        <Link
          to="/shipping"
          className="float-right mt-2 mt-md-0 col-12 col-md-3 col-lg-2"
        >
          <div className="triangle2-incomplete"></div>
          <div className="step incomplete">Teslimat</div>
          <div className="triangle-incomplete"></div>
        </Link>
      )}
     

      
      {confirmOrder ? (
        <Link
          to="/confirm_order"
          className="float-right mt-2 mt-md-0 col-12 col-md-4 col-lg-3"
        >
          <div className="triangle2-active"></div>
          <div className="step active-step">Sipariş Onay</div>
          <div className="triangle-active"></div>
        </Link>
      ) : (
        <Link
          to="/confirm_order"
          className="float-right mt-2 mt-md-0 col-12 col-md-4 col-lg-3"
        >
          <div className="triangle2-incomplete"></div>
          <div className="step incomplete">Sipariş Onay</div>
          <div className="triangle-incomplete"></div>
        </Link>
      )}
      

     {payment ? (
        <Link
          to="/payment_method"
          className="float-right mt-2 mt-md-0 col-12 col-md-3 col-lg-2"
        >
          <div className="triangle2-active"></div>
          <div className="step active-step">Ödeme</div>
          <div className="triangle-active"></div>
        </Link>
      ) : (
        <Link
          to="/payment_method"
          className="float-right mt-2 mt-md-0 col-12 col-md-3 col-lg-2"
        >
          <div className="triangle2-incomplete"></div>
          <div className="step incomplete">Ödeme</div>
          <div className="triangle-incomplete"></div>
        </Link>
      )}
      
    </div>

  );
};

export default CheckOutSteps;