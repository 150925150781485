import React, { useEffect, useState } from 'react';
import MetaData from "../layout/MetaData";
import StarRatings from 'react-star-ratings';
import { toast } from 'react-hot-toast';
import { useCreateReviewMutation } from '../../redux/api/reviewApi';
import { useCanUserReviewQuery } from '../../redux/api/productsApi';

const NewReview = ({ productId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  // API sorgusunun yanıtını alıyoruz
  const { data, error: reviewError, isLoading } = useCanUserReviewQuery(productId);
  const canReview = data?.canReview;

  const [createReview, { error, isSuccess }] = useCreateReviewMutation();

  useEffect(() => {
    
    if (error) {
      toast.error(error?.data?.msg);
    }

    if (isSuccess) {
      toast.success("Yorum Yapıldı");
    }
  }, [error, isSuccess, reviewError]);

  const submitHandler = () => {
    const reviewData = { rating, comment, product: productId };
    createReview(reviewData);
  };

  return (
    <>
      <MetaData title={"Yorum Yap"} />
      <div>
        {isLoading ? (
          <p>Yükleniyor...</p> // Yükleme sırasında mesaj gösteriyoruz
        ) : canReview ? (
          <button
            id="review_btn"
            type="button"
            className="btn btn-primary mt-4"
            data-bs-toggle="modal"
            data-bs-target="#ratingModal"
          >
            Yorum Yap
          </button>
        ) : (
          <p style={{ color: "red" }}>Ürünü satın almamış olduğunuz için, yorum yapma yetkiniz yok.</p> // Kullanıcının yorum yapma yetkisi yoksa
        )}

        <div className="row mt-2 mb-5">
          <div className="rating w-50">
            <div
              className="modal fade"
              id="ratingModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="ratingModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="ratingModalLabel">
                      Yorum Yap
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <StarRatings
                      rating={rating}
                      starRatedColor="#ffb829"
                      numberOfStars={5}
                      name="rating"
                      changeRating={(e) => setRating(e)}
                    />
                    <textarea
                      name="review"
                      id="review"
                      className="form-control mt-4"
                      placeholder="Yorumunuzu girin"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <button
                      id="new_review_btn"
                      className="btn w-100 my-4 px-4"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={submitHandler}
                    >
                      Yorumu Yayınla
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewReview;
