import Pagination from "react-js-pagination";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const CustomPagination = ({ resPerPage, filteredProductsCount }) => {
  const [currentPage, setCurrentPage] = useState();
  let [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const navigate = useNavigate();
  


  useEffect(() => { 
    setCurrentPage(page);
  }, [page]);


  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
   

    if (searchParams.has('page')) {
      searchParams.set('page', pageNumber);
    } else {
      searchParams.append('page', pageNumber);
    }

    const path = window.location.pathname + "?" + searchParams.toString();
    
    navigate(path);

    
  };






  return (
    <div className="d-flex justify-content-center my-5">
      {filteredProductsCount > resPerPage && (
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={resPerPage}
          totalItemsCount={filteredProductsCount}
          onChange={setCurrentPageNo}
          nextPageText="İleri"
          prevPageText="Geri"
          firstPageText="İlk"
          lastPageText="Son"
          itemClass="page-item"
          linkClass="page-link"
          activeClass="pageItemActive"
          activeLinkClass="pageLinkActive"
          
        />
      )}
    </div>
  );
};

export default CustomPagination;