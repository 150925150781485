import axios from 'axios';

export const getChatNotifications = async () => {
  try {
    const response = await axios.get('/api/v1/chat-notifications');
    return response.data;
  } catch (error) {
    console.error('Chat bildirimleri alınırken hata oluştu:', error);
    throw error;
  }
};

export const markChatNotificationAsRead = async (notificationId) => {
  try {
    const response = await axios.patch(`/api/v1/chat-notifications/${notificationId}`);
    return response.data;
  } catch (error) {
    console.error('Chat bildirimi okundu olarak işaretlenirken hata oluştu:', error);
    throw error;
  }
};

export const deleteChatNotification = async (notificationId) => {
  try {
    const response = await axios.delete(`/api/v1/chat-notifications/${notificationId}`);
    return response.data;
  } catch (error) {
    console.error('Chat bildirimi silinirken hata oluştu:', error);
    throw error;
  }
};