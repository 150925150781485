import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { markChatNotificationAsRead } from '../services/chatNotificationService';
import { markChatNotificationAsRead as markAsReadAction } from '../redux/features/NotificationSlace';

const ChatNotifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chatNotifications } = useSelector((state) => state.notification);

  const handleNotificationClick = async (notification) => {
  try {
    await markChatNotificationAsRead(notification._id);
    dispatch(markAsReadAction(notification._id));
    navigate(`/chat`, { state: { selectedUserId: notification.sender } });
  } catch (error) {
    console.error('Bildirim işaretlenirken hata:', error);
  }
};

  return (
    <div className="chat-notifications">
      {chatNotifications.length > 0 ? (
        chatNotifications.map((notification) => (
          <div key={notification._id} className="chat-notification-item">
            <span>{notification.message}</span>
            {!notification.isRead && (
              <button onClick={() => handleNotificationClick(notification)}>Okundu</button>
            )}
          </div>
        ))
      ) : (
        <div className="no-notifications">Sohbet bildirimi yok</div>
      )}
    </div>
  );
};

export default ChatNotifications;