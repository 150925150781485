import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Loader from './../Loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import { useGetAllOrdersQuery, useDeleteOrderMutation } from '../../redux/api/orderApi';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../ConfirmModal';





const ListOrders = () => {
   const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

 const { data, isLoading, error, refetch } = useGetAllOrdersQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const [deleteOrder, { error: deleteError, isSuccess }] = useDeleteOrderMutation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya geri dön
  };

  
  useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
    if (deleteError) {
      toast.error(deleteError?.data?.message || 'Sipariş silinirken bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Sipariş başarıyla silindi.');
      refetch();
    }
  }, [error, deleteError, isSuccess, refetch]);

    const handleDeleteClick = (orderId) => {
    setOrderToDelete(orderId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (orderToDelete) {
      deleteOrder(orderToDelete);
      setShowConfirmModal(false);
      setOrderToDelete(null);
    }
  };

  const setOrders = () => {
    if (!data) return;
    const orders={ 
      columns: [
        {
          label: 'Sipariş ID',
          field: 'id',
          sort: 'asc'
        },
       
        {
          label: 'Sipariş Tarihi',
          field: 'createdAt',
          sort: 'asc'
        },
        {
          label: 'Sipariş Durumu',
          field: 'status',
          sort: 'asc'
        },
        {
          label: 'Ödeme Durumu',
          field: 'paymentStatus',
          sort: 'asc'
        },
        {
          label: 'Ödeme Türü',
          field: 'paymentMethod',
          sort: 'asc'
        },
        {
          label: 'Tutar',
          field: 'itemsAmount',
          sort: 'asc'
        },
        
        {
          label: 'İşlemler',
          field: 'actions',
          sort: 'asc'
        }
      ],
      rows: []
    };
   data?.orders.forEach((order) => {
  orders.rows.push({
    id: order._id,
    createdAt: new Date(order.createdAt).toLocaleString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }),
    status: order.status,
    paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
    paymentMethod: order.paymentMethod,
    itemsAmount: `${new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(order.totalAmount)}`,

   
    actions: <>
      <Link to={`/admin/orders/${order?._id}`} className='btn btn-outline-primary'>
        <i className="fa fa-pencil"></i>
      </Link>
     
     <button 
        className='btn btn-outline-danger ms-2' 
        onClick={() => handleDeleteClick(order._id)}
      >
        <i className="fa fa-trash"></i>
      </button>
    </>
  });
});

    return orders;
  };


  useEffect(() => {
    if (data) {
      setOrders();
    }
  }, [data]);

  if (isLoading) return <Loader/>;
  <MDBDataTable
  data={setOrders()}
  className="px-3"
  bordered
  striped
  hover
  responsive
  />
 
  return (

    <>
      
      <MetaData title={"Sipariş Listesi"} />
    <div>
      <h1 className='my-5'>({data?.orders?.length}) Sipariş listelendi.</h1>
      
       <MDBDataTable
        data={setOrders()}
        className="px-3"
        bordered
        striped
        hover
        responsive
      />
       <button className='btn btn-outline-primary' onClick={handleGoBack}>Geri Dön</button> 
      </div>
      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmDelete}
        title="Siparişi Sil"
        message="Bu siparişi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
      />
        </>
    
  );



};

export default ListOrders;