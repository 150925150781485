import React, { useState, useEffect } from 'react';
import { useGetEmailConfigQuery, useUpdateEmailConfigMutation } from '../../redux/api/emailConfigApi';
import { toast } from 'react-hot-toast';
import AdminLayout from '../layout/AdminLayout';
import MetaData from '../layout/MetaData';

const EmailConfigManager = () => {
  const [formData, setFormData] = useState({ host: '', port: '', user: '', pass: '' });
  const { data: emailConfig, isLoading, isError, error } = useGetEmailConfigQuery();
  const [updateEmailConfig] = useUpdateEmailConfigMutation();

  useEffect(() => {
    if (emailConfig) {
      setFormData(emailConfig.emailConfig);
    }
  }, [emailConfig]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateEmailConfig(formData).unwrap();
      toast.success('E-posta yapılandırması güncellendi');
    } catch (err) {
      toast.error(err?.data?.msg || 'Bir hata oluştu');
    }
  };

  if (isLoading) return <div>Yükleniyor...</div>;
  if (isError) return <div>Bir hata oluştu: {error?.data?.msg}</div>;

  return (
    <AdminLayout>
      <MetaData title="E-posta Yapılandırması" />
      <div className="email-config-manager">
        <h2>E-posta Yapılandırması</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="host">SMTP Host</label>
            <input
              type="text"
              id="host"
              name="host"
              value={formData.host}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="port">SMTP Port</label>
            <input
              type="number"
              id="port"
              name="port"
              value={formData.port}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="user">SMTP Kullanıcı Adı</label>
            <input
              type="text"
              id="user"
              name="user"
              value={formData.user}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="pass">SMTP Şifresi</label>
            <input
              type="password"
              id="pass"
              name="pass"
              value={formData.pass}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Güncelle</button>
        </form>
      </div>
    </AdminLayout>
  );
};

export default EmailConfigManager;