import React from 'react';
import MetaData from "../layout/MetaData";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { calculateOrdersConst } from '../../helpers/helpers';
import CheckOutSteps from './CheckOutSteps';

const ConfirmOrder = () => {
  const { cartItems, shippingInfo } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);
  const { itemPrice, shippingPrice, taxPrice, totalPrice } = calculateOrdersConst(cartItems)

  return (
    <>
      <MetaData title={"Sipariş Onay"} />

      <CheckOutSteps shipping confirmOrder />
      <div className="row d-flex justify-content-between">
        <div className="col-12 col-lg-8 mt-5 order-confirm">
          <h4 className="mb-3">Adres Bilgileri</h4>
          <p><b>Adı:</b> {user.name}</p>
          <p><b>Telefon:</b> {shippingInfo.phoneNo}</p>
          <p className="mb-4">
            <b>Adres:</b> {`${shippingInfo.address}, ${shippingInfo.state}, ${shippingInfo.city}, ${shippingInfo.country}`}
          </p>

          <hr />
          <h4 className="mt-4">Sepet İçeriği:</h4>

          {cartItems.map((item) => (
  <React.Fragment key={item.id}>
    <hr />
    <div className="cart-item my-1">
      <div className="row w-100">
        <div className="col-12 col-md-6 d-flex align-items-center">
          <img
            src={item?.image}
            alt={item.name}
            height="45"
            width="65"
            className="me-3"
          />
          <Link to={`/product/${item.id}`} className="text-truncate">{item.name}</Link>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
          <p className="mb-0">
            {item.quantity} x {(item.price).toFixed(2)}₺ = <strong>{(item.quantity * item.price).toFixed(2)}₺</strong>
          </p>
        </div>
      </div>
    </div>
    <hr />
  </React.Fragment>
))}
        </div>

        <div className="col-12 col-lg-3 my-4">
          <div id="order_summary">
            <h4>Sipariş Özeti</h4>
            <hr />
            <p>Alt Toplam: <span className="order-summary-values">{itemPrice}₺</span></p>
            <p>Kargo Ücreti: <span className="order-summary-values">{shippingPrice}₺</span></p>
            <p>Kdv: <span className="order-summary-values">{taxPrice}₺</span></p>

            <hr />

            <p>Genel Toplam: <span className="order-summary-values">{totalPrice}₺</span></p>

            <hr />
            <Link to="/payment_method" id="checkout_btn" className="btn btn-primary w-100">
              Ödeme İşlemleri
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
