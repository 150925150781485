import React, { useState, useEffect } from 'react';
import AdminLayout from '../layout/AdminLayout';
import { useLazyGetProductReviewsQuery, useDeleteReviewMutation } from '../../redux/api/reviewApi'
import { MDBDataTable } from 'mdbreact';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import { useGetAdminProductsQuery } from '../../redux/api/productsApi';
import MetaData from '../layout/MetaData';
const ProductReviews = () => {
  const [productId, setProductId] = useState('');

  const [getAllReviews, { data, isLoading, error }] =
    useLazyGetProductReviewsQuery();

const { data: productsData, isLoading: isProductsLoading } = useGetAdminProductsQuery();

   const [
  deleteReview,
  { error: deleteError, isLoading: isDeleteLoading, isSuccess, data: deleteData },
] = useDeleteReviewMutation();

useEffect(() => {
  if (error) {
    toast.error(error?.data?.message || 'Bir hata oluştu');
  }

  if (deleteError) {
    toast.error(deleteError?.data?.message || 'Silme işlemi sırasında bir hata oluştu');
  }

  if (isSuccess) {
    toast.success(deleteData.msg || "Yorum silindi.");
    // Yorumları yeniden yükle
    if (productId) {
      getAllReviews(productId);
    }
  }
}, [error, deleteError, isSuccess, deleteData, productId, getAllReviews]);

  
  const submitHandler = (e) => {
    e.preventDefault();
    getAllReviews(productId);
  };

 const deleteReviewHandler = async (id) => {
  try {
    await deleteReview(id).unwrap();
  } catch (err) {
    console.error('Yorum silinirken bir hata oluştu:', err);
  }
};


  const setReviews = () => {
    const reviews = {
      columns: [
        {
          label: "Yorum ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Rating",
          field: "rating",
          sort: "asc",
        },
        {
          label: "Yorum",
          field: "comment",
          sort: "asc",
        },
        {
          label: "Kullanıcı",
          field: "user",
          sort: "asc",
        },
        {
          label: "İşlemler",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    data?.reviews?.forEach((review) => {
      reviews.rows.push({
        id: review?._id,
        rating: review?.rating,
        comment: review?.comment,
        user: review?.user?.name,
        actions: (
          <>
            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => deleteReviewHandler(review?._id)}
              disabled={isDeleteLoading}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        ),
      });
    });

    return reviews;
  };

   if (isLoading) return <Loader />;
 
  return (
  
    <AdminLayout>
      <MetaData title={"Ürün Yorumları"} />
     <div className="row justify-content-center my-5">
        <div className="col-6">
          <form onSubmit={submitHandler}>
            <div className="mb-3">
              <label htmlFor="productId_field" className="form-label">
                Ürün Seçin
              </label>
              <select
                id="productId_field"
                className="form-select"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
              >
                <option value="">Ürün Seçin</option>
                {productsData?.products?.map((product) => (
                  <option key={product._id} value={product._id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>

            <button
              id="search_button"
              type="submit"
              className="btn btn-primary w-100 py-2"
              disabled={!productId}
            >
              Yorumları Getir
            </button>
          </form>
        </div>
      </div>
     

      {data?.reviews?.length > 0 ? (
        <MDBDataTable
          data={setReviews()}
          className="px-3"
          bordered
          striped
          hover
          responsive
        />
      ) : (
        <p className="mt-5 text-center">İnceleme Yok</p>
      )}
    </AdminLayout>
  );
};

export default ProductReviews;