import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { initSocket, getSocket, sendMessage, getMessages, closeSocket } from '../services/socketService';
import { useGetChatUsersQuery } from '../redux/api/userApi';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const messageListRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const inputRef = useRef(null);

  const { data: users, isLoading: isLoadingUsers, error: usersError } = useGetChatUsersQuery();

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (user && user._id) {
      const newSocket = initSocket(user._id, user.role === 'admin');
      setSocket(newSocket);

      newSocket.on('newMessage', handleNewMessage);
      newSocket.on('loadMessages', handleLoadMessages);

      return () => {
        newSocket.off('newMessage', handleNewMessage);
        newSocket.off('loadMessages', handleLoadMessages);
        closeSocket();
      };
    }
  }, [user]);

  const handleNewMessage = (message) => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const handleLoadMessages = (loadedMessages) => {
    setMessages(loadedMessages);
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleUserSelect = (selectedUser) => {
    setSelectedUser(selectedUser);
    if (socket && user) {
      getMessages(user._id, selectedUser._id, handleLoadMessages);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '' && socket && selectedUser && user && user._id) {
      const tempMessage = {
        tempId: `temp-${Date.now()}`,
        sender: user._id,
        receiver: selectedUser._id,
        content: inputMessage,
        timestamp: new Date().toISOString(),
      };
      try {
        setMessages(prevMessages => [...prevMessages, tempMessage]);
        setInputMessage('');
        
        const sentMessage = await sendMessage(user._id, selectedUser._id, inputMessage);
        
        setMessages(prevMessages => prevMessages.map(msg => 
          msg.tempId === tempMessage.tempId ? { ...sentMessage, _id: sentMessage._id } : msg
        ));
      } catch (error) {
        console.error('Mesaj gönderme hatası:', error);
        alert('Mesaj gönderilemedi. Lütfen tekrar deneyin.');
        setMessages(prevMessages => prevMessages.filter(msg => msg.tempId !== tempMessage.tempId));
      }
    }
  };

  if (isLoadingUsers) return <div className="chat-loading">Kullanıcılar yükleniyor...</div>;
  if (usersError) return <div className="chat-error">Kullanıcılar yüklenirken bir hata oluştu: {usersError.message}</div>;

  return (
    <div className="chat">
      <div className="chat-container">
        <div className="chat-sidebar">
          <div className="chat-users">
            {users && users.users.map((u) => (
              <div
                key={u._id}
                className={`chat-user ${selectedUser && selectedUser._id === u._id ? 'selected' : ''}`}
                onClick={() => handleUserSelect(u)}
              >
                {u.name}
              </div>
            ))}
          </div>
        </div>
        <div className="chat-main">
          <div className="chat-messages" ref={messageListRef}>
            {messages.map((message) => (
              <div
                key={message._id || message.tempId}
                className={`chat-message ${message.sender === user._id ? 'sent' : 'received'}`}
              >
                <span className="chat-message-content">{message.content}</span>
                <span className="chat-message-time">
                  {new Date(message.timestamp).toLocaleTimeString()}
                </span>
              </div>
            ))}
          </div>
          <form onSubmit={handleSendMessage} className="chat-form">
            <input
              ref={inputRef}
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onTouchStart={focusInput}
              onClick={focusInput}
              placeholder="Mesajınızı yazın..."
              className="chat-input"
              disabled={!selectedUser}
            />
            <button 
              type="submit" 
              className="chat-button" 
              disabled={!selectedUser}
            >
              Gönder
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;