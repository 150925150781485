import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateBrandMutation } from "../../redux/api/brandApi";
import { toast } from 'react-hot-toast';
import MetaData from "../layout/MetaData";
import AdminLayout from '../layout/AdminLayout';

const NewBrand = () => {
  const navigate = useNavigate();

  const [brand, setBrand] = useState({

    name: ""
   
  });

  const { name } = brand;

  const [createBrand, { isLoading, error, isSuccess }] = useCreateBrandMutation();
    
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.msg);
    }

    if (isSuccess) {
      toast.success("Marka eklendi");
      navigate("/admin/brands");
    }
  }, [error, isSuccess, navigate]);

   const onChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setBrand({ ...brand, [e.target.name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createBrand(brand);
  };

  return (
   
    <>
      <AdminLayout>
     <MetaData title={"Marka Ekle"} />
    <div className="row wrapper">
      <div className="col-10 col-lg-10 mt-5 mt-lg-0">
        <form className="shadow rounded bg-body" onSubmit={submitHandler}>
          <h2 className="mb-4">Yeni Marka</h2>
          <div className="mb-3">
            <label htmlFor="name_field" className="form-label"> Marka Adı </label>
            <input
              type="text"
              id="name_field"
              className="form-control"
              name="name"
              value={name}
              onChange={onChange}
            />
          </div>

      
          
          <button type="submit" className="btn w-100 py-2" disabled={isLoading}>
            {isLoading ? "Oluşturuluyor..." : "OLUŞTUR"}
          </button>
        </form>
      </div>
      </div>
      </AdminLayout>
      </>
  );
};

export default NewBrand;
