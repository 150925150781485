import React, { useEffect, useState } from 'react';
import { useRegisterMutation } from '../../redux/api/authApi';
import { toast } from 'react-hot-toast';
import MetaData from '../layout/MetaData';

const Register = () => {
  const [user, setUser] = useState({ name: '', email: '', password: '' });
  const { name, email, password } = user;
  const [register, { isLoading, error, isSuccess}] = useRegisterMutation();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.msg || 'Bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('E-posta adresinizi kontrol ediniz. E-posta adresinizi onaylamanız gerekmektedir.');
    }
  }, [error,isSuccess]);


  const submitHandler = (e) => {
    e.preventDefault();

    const signUpData = {
      name,
      email,
      password,
    };

    register(signUpData);
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };



  return (
    <>
      <MetaData title={"Kayıt Ol"} />
     <div className="row wrapper">
      <div className="col-10 col-lg-5">
        <form
          className="shadow rounded bg-body"
          action="your_submit_url_here"
          onSubmit={submitHandler}
        >
          <h2 className="mb-4">Kayıt Ol</h2>

          <div className="mb-3">
            <label htmlFor="name_field" className="form-label">Adı Soyadı</label>
            <input
  type="text"
  id="name_field"
  className="form-control"
  name="name" // Bu satırda name prop'unu string olarak tanımladık
  value={name} // value prop'una state'teki name değerini atadık
  onChange={onChange} // onChange handler'ını ekledik
/>
          </div>

          <div className="mb-3">
            <label htmlFor="email_field" className="form-label">E-Posta</label>
           <input
  type="email"
  id="email_field"
  className="form-control"
  name="email" // Bu satırda name prop'unu string olarak tanımladık
  value={email} // value prop'una state'teki email değerini atadık
  onChange={onChange} // onChange handler'ını ekledik
/>
          </div>

          <div className="mb-3">
            <label htmlFor="password_field" className="form-label">Şifre</label>
            <input
             type="password"
  id="password_field"
  className="form-control"
  name="password" // Bu satırda name prop'unu string olarak tanımladık
  value={password} // value prop'una state'teki password değerini atadık
  onChange={onChange} // onChange handler'ını ekledik
            />
          </div>

          <button id="register_button" type="submit" className="btn w-100 py-2" disabled={isLoading}>
            {isLoading ? <i className="fa fa-spinner fa-spin"></i> : 'Kayıt Ol'}
          </button>
        </form>
      </div>
      </div>
    </>
  );
};

export default Register;