import React, { useEffect, useState } from 'react';
import { useGetAdminProductsQuery, useDeleteProductMutation } from '../../redux/api/productsApi';
import { toast } from 'react-hot-toast';
import Loader from './../Loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import ConfirmModal from '../ConfirmModal';
import { useNavigate } from 'react-router-dom';



const ListProducts = () => {
   const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [deleteProduct, {isLoading:isDeleteLoading, isSuccess,  error:deleteError}] = useDeleteProductMutation();
 const { data, isLoading, error, refetch } = useGetAdminProductsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
 
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya geri dön
  };

  
  useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
    if (deleteError) {
      toast.error(deleteError?.data?.message || 'Ürün silinirken bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Ürün başarıyla silindi.');
      refetch();
    }
  }, [error, isSuccess, navigate, deleteError, refetch]);

    
  const handleDeleteClick = (productId) => {
    setProductToDelete(productId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (productToDelete) {
      deleteProduct(productToDelete);
      setShowConfirmModal(false);
      setProductToDelete(null);
    }
  };
  

  const setProducts = () => {
    if (!data) return;
    const products={ 
      columns: [
        {
          label: 'Ürün ID',
          field: 'id',
          sort: 'asc'
        },
        {
          label: 'Ürün Adı',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Stok Adedi',
          field: 'stock',
          sort: 'asc'
        },

        {
          label: 'Fiyat',
          field: 'price',
          sort: 'asc'
        },
        {
          label: 'Kategori',
          field: 'category',
          sort: 'asc'
        },
        {
          label: 'Marka',
          field: 'brand',
          sort: 'asc'
        },
        {
          label: 'İşlemler',
          field: 'actions',
          sort: 'asc'
        }
      ],
      rows: []
    };
   data?.products.forEach((product) => {
  products.rows.push({
    id: product._id,
    name: product.name,
    stock: product.stock,
    price: `${(product.price).toFixed(2)}₺`,
    category: product.category.name, // category objesinden name'i al
    brand: product.brand.name, // brand objesinden name'i al
    actions: <>
      <Link to={`/admin/products/${product._id}`} className='btn btn-outline-primary'>
        <i className="fa fa-pencil"></i>
      </Link>
      <Link to={`/admin/products/${product._id}/upload_images`} className='btn btn-outline-success ms-2' disabled={isDeleteLoading}>
        <i className="fa fa-image"></i>
      </Link>
      <button 
        className='btn btn-outline-danger ms-2' 
        onClick={() => handleDeleteClick(product._id)}
      >
        <i className="fa fa-trash"></i>
      </button>
    </>
  });
});

    return products;
  };


  useEffect(() => {
    if (data) {
      setProducts();
    }
  }, [data]);

  if (isLoading) return <Loader/>;
  <MDBDataTable
  data={setProducts()}
  className="px-3"
  bordered
  striped
  hover
  responsive
  />
 
  return (

    <>
      
      <MetaData title={"Ürün Listesi"} />
    <div>
      <h1 className='my-5'>({data?.products?.length}) Ürün listelendi.</h1>
      
       <MDBDataTable
        data={setProducts()}
        className="px-3"
        bordered
        striped
        hover
        responsive
      />
       <button className='btn btn-outline-primary' onClick={handleGoBack}>Geri Dön</button> 
        </div>
        <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmDelete}
        title="Ürünü Sil"
        message="Bu ürünü silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
      />
        </>
    
  );



};

export default ListProducts;