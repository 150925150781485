import { useSelector } from "react-redux";
import {Navigate} from 'react-router-dom'
import Loader from "../Loader";

const ProtectedRoutes = ({admin, children }) => {



  const { isAuthenticated,user, loading } = useSelector((state) => state.auth);

  if (loading) {
    return <Loader />;
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace/>;
  }

  if (admin && user?.role !== 'admin') {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectedRoutes;