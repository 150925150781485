import React, { useEffect, useState } from 'react';
import { useGetAllCategoriesQuery, useDeleteCategoryMutation } from '../../redux/api/categoriesApi';
import { toast } from 'react-hot-toast';
import Loader from './../Loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import ConfirmModal from '../ConfirmModal';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './../layout/AdminLayout';



const ListCategory = () => {
   const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [deleteCategory, {isLoading:isDeleteLoading, isSuccess,  error:deleteError}] = useDeleteCategoryMutation();
 const { data, isLoading, error, refetch } = useGetAllCategoriesQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
 
  const navigate = useNavigate();

 

  
  useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
    if (deleteError) {
      toast.error(deleteError?.data?.message || 'Kategori silinirken bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Kategori başarıyla silindi.');
      refetch();
    }
  }, [error, isSuccess, navigate, deleteError, refetch]);


  const handleDeleteClick = (categoryId) => {
    setCategoryToDelete(categoryId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (categoryToDelete) {
      deleteCategory(categoryToDelete);
      setShowConfirmModal(false);
      setCategoryToDelete(null);
    }
  };
  

  const setCategories = () => {
    if (!data) return;
    const categories={ 
      columns: [
        {
          label: 'Kategori ID',
          field: 'id',
          sort: 'asc'
        },
        {
          label: 'Kategori Adı',
          field: 'name',
          sort: 'asc'
        },
        
        {
          label: 'İşlemler',
          field: 'actions',
          sort: 'asc'
        }
      ],
      rows: []
    };
   data?.categories.forEach((category) => {
     categories.rows.push({
       id: category._id,
       name: category.name,
       
  
    actions: <>
      <Link to={`/admin/categories/${category._id}`} className='btn btn-outline-primary'>
        <i className="fa fa-pencil"></i>
      </Link>
      {/* <Link to={`/admin/categories/${category._id}`} className='btn btn-outline-success ms-2' disabled={isDeleteLoading}>
        <i className="fa fa-image"></i>
      </Link> */}
      <button 
        className='btn btn-outline-danger ms-2' 
        onClick={() => handleDeleteClick(category._id)}
      >
        <i className="fa fa-trash"></i>
      </button>
    </>
  });
});

    return categories;
  };


  useEffect(() => {
    if (data) {
      setCategories();
    }
  }, [data]);

  if (isLoading) return <Loader/>;
  <MDBDataTable
  data={setCategories()}
  className="px-3"
  bordered
  striped
    hover
    responsive
  />
 
  return (

    <>
      <AdminLayout>
      <MetaData title={"Kategori Listesi"} />
    <div>
      <h1 className='my-5'>({data?.categories?.length}) Kategori listelendi.</h1>
      
       <MDBDataTable
        data={setCategories()}
        className="px-3"
        bordered
        striped
        hover
        responsive
      />
     
        </div>
        <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmDelete}
        title="Kategoriyi Sil"
        message="Bu kategoriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
        />
        </AdminLayout>
        </>
    
  );



};

export default ListCategory;