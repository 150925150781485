import { configureStore } from '@reduxjs/toolkit'
import { productsApi } from './api/productsApi'
import { categoriesApi } from './api/categoriesApi'
import { authApi } from './api/authApi'
import { userApi } from './api/userApi'
import { orderApi } from './api/orderApi'
import { reviewApi } from './api/reviewApi'
import { brandApi } from './api/brandApi'
import { iyzipayInfoApi } from './api/iyzipayInfoApi'
import userReducer from './features/userSlice'
import cartReducer from './features/cartSlice'
import { companyInfoApi } from './api/companyInfoApi'
import notificationReducer from './features/NotificationSlace'
import { emailConfigApi } from './api/emailConfigApi'
export const store = configureStore({
  reducer: {
    auth: userReducer,
    cart: cartReducer,
    notification: notificationReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [iyzipayInfoApi.reducerPath]: iyzipayInfoApi.reducer,
    [companyInfoApi.reducerPath]: companyInfoApi.reducer,
    [emailConfigApi.reducerPath]: emailConfigApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Non-serializable verileri kabul etmek için
    }).concat([
      productsApi.middleware,
      categoriesApi.middleware,
      authApi.middleware,
      userApi.middleware,
      orderApi.middleware,
      reviewApi.middleware,
      brandApi.middleware,
      iyzipayInfoApi.middleware,
      companyInfoApi.middleware,
      emailConfigApi.middleware,
    ]),
});
