import React from 'react';
import { useGetOrCreateCompanyInfoQuery } from '../redux/api/companyInfoApi';
import { FaWhatsapp, FaTwitter, FaInstagram } from 'react-icons/fa';
import Loader from './Loader';
import MetaData from './layout/MetaData';
import { Link } from 'react-router-dom';

const Contact = () => {
  const { data: getOrCreateCompanyInfo, isLoading: companyInfoLoading, error: companyInfoError } = useGetOrCreateCompanyInfoQuery();
  const companyInfo = getOrCreateCompanyInfo?.companyInfo || {};

  if (companyInfoLoading) return <Loader />;
  if (companyInfoError) return <div>Firma bilgileri yüklenirken bir hata oluştu.</div>;

  const formatWhatsAppNumber = (number) => {
    // Telefon numarasından boşlukları ve özel karakterleri kaldır
    return number.replace(/\s+/g, '').replace(/[^\d]/g, '');
  };

  return (
    <>
      <MetaData title={"İletişim"} />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">****************************************************</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">İLETİŞİM BİLGİLERİMİZ:</h2>
          <p className="font-bold">{companyInfo.name}</p>
          <p>{companyInfo.address}</p>
          <p>{companyInfo.phone}</p>
          <p>{companyInfo.email}</p>
          
          <div className="mt-4 social-media-links"> {/* space-x-4'ü space-x-8 olarak değiştirdik */}
            {companyInfo.whatsapp && (
              <Link 
                to={`https://wa.me/${formatWhatsAppNumber(companyInfo.whatsapp)}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center text-green-600 hover:text-green-800"
              >
                <FaWhatsapp className="mr-2" size={24} />
                <span>WhatsApp</span>
              </Link>
            )}
            {companyInfo.twitter && (
              <Link 
                to={companyInfo.twitter} 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center text-blue-400 hover:text-blue-600"
              >
                <FaTwitter className="mr-2" size={24} />
                <span>Twitter</span>
              </Link>
            )}
            {companyInfo.instagram && (
              <Link 
                to={companyInfo.instagram} 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center text-pink-600 hover:text-pink-800"
              >
                <FaInstagram className="mr-2" size={24} />
                <span>Instagram</span>
              </Link>
            )}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">BANKA HESAP NUMARALARIMIZ:</h2>
          
          {companyInfo.bank1 && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">{companyInfo.bank1.bankName}:</h3>
              <p className="font-bold">{companyInfo.bank1.accountHolder}</p>
              <p>IBAN: {companyInfo.bank1.bankIBAN}</p>
              <p>Hesap No: {companyInfo.bank1.bankAccountNumber}</p>
            </div>
          )}

          {companyInfo.bank2 && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">{companyInfo.bank2.bankName}:</h3>
              <p className="font-bold">{companyInfo.bank2.accountHolder}</p>
              <p>IBAN: {companyInfo.bank2.bankIBAN}</p>
              <p>Hesap No: {companyInfo.bank2.bankAccountNumber}</p>
            </div>
          )}
        </section>
      </div>
      <h1 className="text-3xl font-bold mb-6">****************************************************</h1>
    </>
  );
};

export default Contact;