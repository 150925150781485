import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserMutation } from '../../redux/api/userApi';
import { useSelector } from 'react-redux';
import UserLayout from './../layout/UserLayout';
import MetaData from './../layout/MetaData';


  const UpdateProfile = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
    const [updateUser, { isLoading, error, isSuccess }] = useUpdateUserMutation();
    const {user}= useSelector((state) => state.auth);
    
    useEffect(() => { 
      if (user) {
        setName(user.name);
        setEmail(user.email);
      }
      if (error)
        toast.error(error?.data?.msg || error.message);

      if (isSuccess) {
        toast.success('Profiliniz güncellendi');
        navigate('/me/profile');
      }

    }, [user, error, isSuccess, navigate]);



    const submitHandler = (e) => {
      e.preventDefault();
     const userData = {
  id: user._id,  // User ID'yi buraya ekleyin
  name,
  email,
};
updateUser(userData);

    };

    return (
      <UserLayout>
        <>
      <MetaData title={"Profilinizi Düzenleyin"} />
  <div className="row wrapper">
      <div className="col-10 col-lg-8">
        <form
          className="shadow rounded bg-body"
          onSubmit={submitHandler}
        >
          <h2 className="mb-4">Profil Güncelle</h2>

          <div className="mb-3">
            <label htmlFor="name_field" className="form-label"> Ad Soyad </label>
            <input
              type="text"
              id="name_field"
              className="form-control"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email_field" className="form-label"> E-Posta </label>
            <input
              type="email"
              id="email_field"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button type="submit" className="btn update-btn w-100" disabled={isLoading}>
            {isLoading ? 'Yükleniyor...' : 'Güncelle'}
          </button>
        </form>
      </div>
          </div>
</>
</UserLayout>
  );
};

export default UpdateProfile;