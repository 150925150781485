import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateBrandMutation, useGetBrandQuery } from '../../redux/api/brandApi';
import { toast } from 'react-hot-toast';
import AdminLayout from '../layout/AdminLayout';
import MetaData from "../layout/MetaData";

const UpdateBrand = () => {
  const navigate = useNavigate();
  const params = useParams();


  const [brand, setBrand] = useState({
    id: "",
    name: "",
 
  });
  const { name, id} = brand;

 const [updateBrand, { isLoading, error, isSuccess }] =
    useUpdateBrandMutation();

  const { data } = useGetBrandQuery(params?.id);


  useEffect(() => {
  if (data?.brand) {
    setBrand({
      id: data.brand._id,
      name: data.brand.name
    });
  }

   const handleUpdate = async () => {
    if (isSuccess) {
      toast.success("Marka güncellendi");
      navigate("/admin/brands");
    }

    if (error) {
      toast.error(error?.data?.message || "Bir hata oluştu");
    }
  };

  handleUpdate(); // Mutasyon tamamlandığında veya hata oluştuğunda toast'ları göster

}, [error, isSuccess, navigate, data, updateBrand]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setBrand({
      ...brand,
      [name]: value,
    });
  };

 const submitHandler = async (e) => {
  e.preventDefault();

  try {
    const result = await updateBrand({
      id: params?.id,
      body: brand,
    }).unwrap();

    if (result.brand) {
      toast.success("Marka başarıyla güncellendi");
      navigate("/admin/brands");
    }
  } catch (err) {
    toast.error(err?.data?.message || "Marka güncellenirken bir hata oluştu");
  }
};


  return (
    <>
    <AdminLayout>
      <MetaData title={"Kategori Güncelle"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Marka Güncelle</h2>
            <div className="mb-3">
              <label htmlFor="name_field" className="form-label"> Marka Adı </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

           
            
            <button type="submit" className="btn w-100 py-2" disabled={isLoading}>
              {isLoading ? "Güncelleniyor..." : "Markayı Güncelle"}
            </button>
          </form>
        </div>
        </div>
        </AdminLayout>
    </>
  );
};

export default UpdateBrand;
