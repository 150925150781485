import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductCardSkeleton = () => {
    return (
        <div className='product_card' style={{ width: '275px', height: '400px' }}>
            <Skeleton height={200} />
            <Skeleton height={20} width="80%" style={{ marginBottom: '10px' }} />
            <Skeleton height={20} width="60%" style={{ marginBottom: '10px' }} />
            <Skeleton height={20} width="40%" />
        </div>
    );
};

export default ProductCardSkeleton;