import React, { useEffect } from 'react';
import MetaData from '../layout/MetaData';
import { useState } from 'react';
import { countries } from 'countries-list'
import { saveShippingInfo } from '../../redux/features/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckOutSteps from './CheckOutSteps';
const Shipping = () => {
 const navigate=useNavigate()

  const dispatch=useDispatch()

  const countriesList = Object.values(countries);
  const [userName, setUserName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState(''); 
  const [country, setCountry] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

const {shippingInfo}=useSelector(state=>state.cart)
 
  useEffect(() => {
    if (shippingInfo) {
      setUserName(shippingInfo.userName);
      setUserLastName(shippingInfo.userLastName);
      setAddress(shippingInfo.address);
      setState(shippingInfo.state);
      setCity(shippingInfo.city);
      setCountry(shippingInfo.country);
      setPhoneNo(shippingInfo.phoneNo);
    }
  }, [shippingInfo]);



  const submitHandler = (e) => {
    e.preventDefault();
    
    dispatch(saveShippingInfo({ address, state, city, country, phoneNo, userName, userLastName }));
    navigate("/confirm_order");

  };


  return (

    <>
      <MetaData title={"Teslimat Bilgileri"} />
      
      <CheckOutSteps shipping />
     <div className="row wrapper mb-5">
      <div className="col-10 col-lg-5">
        <form
          className="shadow rounded bg-body"
          onSubmit={submitHandler}
        >
            <h2 className="mb-4">Teslimat Bilgileri</h2>

             <div className="mb-3">
            <label htmlFor="city_field" className="form-label">Adı</label>
            <input
              type="text"
              id="city_field"
              className="form-control"
              name="userName"
                value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            </div>
            

            <div className="mb-3">
            <label htmlFor="city_field" className="form-label">Soyadı</label>
            <input
              type="text"
              id="city_field"
              className="form-control"
              name="userLastName"
                value={userLastName}
              onChange={(e) => setUserLastName(e.target.value)}
              required
            />
        </div>  
        
          <div className="mb-3">
            <label htmlFor="address_field" className="form-label">Adres</label>
            <input
              type="text"
              id="address_field"
              className="form-control"
              name="address"
                value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

            <div className="mb-3">
            <label htmlFor="postal_code_field" className="form-label"
              >İlçe</label
            >
            <input
              type="text"
              id="city_field"
              className="form-control"
              name="state"
                value={state}
              onChange={(e) => setState(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="city_field" className="form-label">Şehir</label>
            <input
              type="text"
              id="city_field"
              className="form-control"
              name="city"
                value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="phone_field" className="form-label">Telefon No</label>
            <input
              type="tel"
              id="phone_field"
              className="form-control"
              name="phoneNo"
                value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              required
            />
          </div>

        

          <div className="mb-3">
            <label htmlFor="country_field" className="form-label">Ülke</label>
            <select
              id="country_field"
              className="form-select"
                name="country"
                value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            >
             {countriesList.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name}
              </option>
            ))}
             
            </select>
          </div>

          <button id="shipping_btn" type="submit" className="btn w-100 py-2" onClick={submitHandler}>
            Devam Et
          </button>
        </form>
      </div>
      </div>
    </>

  );
};

export default Shipping;