import { useGetSingleProductQuery } from '../../redux/api/productsApi';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Loader from '../Loader';
import { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems } from '../../redux/features/cartSlice';
import MetaData from '../layout/MetaData';
import NewReview from '../reviews/NewReview';
import ListReviews from '../reviews/ListReviews';
import NotFound from '../layout/NotFound';


const ProductDetails = () => {
  const params = useParams();
  const { data, isLoading, error, isError } = useGetSingleProductQuery(params?.id);
  const product = data?.product;

  const [quantity, setQuantity] = useState(1);
  const [activeImg, setActiveImg] = useState('');

  const dispatch = useDispatch();
  
  const {isAuthenticated}=useSelector(state=>state.auth)
  

  useEffect(() => {
    setActiveImg(product?.images[0] ? product?.images[0]?.url : '/images/default_product.png');
  }, [product]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.msg);
    }
  }, [isError, error]);

  const decreaseQty = () => {
    const count = document.querySelector('.count');
    
    if (count.valueAsNumber <= 1) return;

    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
   
  };

  const increaseQty = () => {
    const count = document.querySelector('.count');
    
    if (count.valueAsNumber >= product.stock) return;

    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };

  const addToCart = () => {
    const cartItem = {
      id: product._id,
      name: product.name,
      price: product.price,
      image: product?.images[0]?.url,
      stock: product?.stock,
      quantity,
    };
    dispatch(setCartItems(cartItem));
    toast.success('Ürün sepete eklendi');

  }
  

  if (isLoading) return <Loader />;

   if (error && error.status === 404) return <NotFound />;

  return (
    <>
      <MetaData title={product?.name} />
    <div className="row d-flex justify-content-around">
           <div className="col-12 col-lg-5 img-fluid" id="product_image">
  <div className="main-image-wrapper">
    <img
      className="main-image"
      src={activeImg}
      alt={product?.name}
    />
  </div>

  <div className="thumbnails">
    {Array.isArray(product?.images) && product.images.map((img) => (
      <div className="thumbnail-wrapper" key={img._id}>
        <img
          className={`thumbnail ${img.url === activeImg ? 'active' : ''}`}
          src={img.url}
          alt={img.url}
          onClick={() => setActiveImg(img.url)}
        />
      </div>
    ))}
  </div>
</div>

      <div className="col-12 col-lg-5 mt-5">
        <h3>{product?.name}</h3>
        <p id="product_id">Ürün # {product?._id}</p>

        <hr />

        <div className="d-flex">
          <StarRatings
                        rating={product?.averageRating}
                        starRatedColor="#ffb829"
                        numberOfStars={5}
                        name='rating'
                        starDimension="24px"
                        starSpacing="1px"
                       />
          <span id="no-of-reviews" className="pt-1 ps-2"> ({product?.numOfReviews} Yorum) </span>
        </div>
        <hr />

        <p id="product_price">{product?.price}₺</p>
        <div className="stockCounter d-inline">
          <span className="btn btn-danger minus" onClick={decreaseQty}>-</span>
          <input
            type="number"
            className="form-control count d-inline"
            value={quantity}
            readonly
          />
          <span className="btn btn-primary plus" onClick={increaseQty}>+</span>
        </div>
        <button
          type="button"
          id="cart_btn"
          className="btn btn-primary d-inline ms-4"
          disabled={product?.stock < 1}
          onClick={addToCart}
        >
          Sepete Ekle
        </button>

        <hr />

        <p>
          Stok Durumu: <span id="stock_status" className={product?.stock > 0 ? "greenColor" : "redColor"}>
            {product?.stock > 0 ? "Stokta Mevcut" : "Stokta Yok"}
          </span>
        </p>

        <hr />

        <h4 className="mt-2">Ürün Açıklaması:</h4>
        <p>
          {product?.description}
        </p>
        <hr />
        <p id="product_seller mb-3">Marka: <strong>{product?.brand.name}</strong></p>

          {isAuthenticated ? <NewReview productId={product?._id} /> : 
            <div className="alert alert-danger my-5" type="alert">
          Yorum yapmak için giriş yapın.
        </div>
          }
        
      </div>
      </div>

       {product?.reviews?.length > 0 && (
        <ListReviews  productId={product?._id}/>
      )}
    </>
  );
};

export default ProductDetails;