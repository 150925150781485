import { Route } from "react-router-dom";
import Home from '../Home';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Profile from '../user/Profile';
import ProductDetails from '../product/ProductDetails';
import UpdateProfile from '../user/UpdateProfile';
import ProtectedRoutes from '../auth/ProtectedRoutes';
import UploadAvatar from '../user/UploadAvatar';
import UpdatePassword from '../user/UpdatePassword';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import Cart from '../cart/Cart';
import Shipping from '../cart/Shipping';
import ConfirmOrder from '../cart/ConfirmOrder';
import PaymentMethod from '../cart/PaymentMethod';
import MyOrders from '../order/MyOrders';
import OrderDetails from '../order/OrderDetails';
import Invoice from '../invoices/Invoice';
import NewReview from '../reviews/NewReview';
import HomePage from "../home/HomePage";
import OrderSuccess from "../order/OrderSuccess";
import Contact from "../Contact";
import Chat from "../Chat";






const UserRoutes = () => {
  return (
    <>
      
           <Route path="/" element={<HomePage />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/store_products" element={<Home />} />
      <Route path="/user/reset-password" element={<ResetPassword />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/chat" element={<Chat />} />
            
     

            

            {/* Protected Routes */}
            <Route
              path="/me/profile"
              element={
                <ProtectedRoutes>
                  <Profile />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/me/update_profile"
              element={
                <ProtectedRoutes>
                  <UpdateProfile />
                </ProtectedRoutes>
              }
            />
             <Route
              path="/me/upload_avatar"
              element={
                <ProtectedRoutes>
                  <UploadAvatar />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/me/update_password"
              element={
                <ProtectedRoutes>
                  <UpdatePassword />
                </ProtectedRoutes>
              }
            />

            <Route
              path="/cart" element={
                <ProtectedRoutes>
                  <Cart />
                  </ProtectedRoutes>
              } />

            <Route
              path="/shipping" element={
                <ProtectedRoutes>
                  <Shipping />
                  </ProtectedRoutes>
              } />

            <Route
              path="/confirm_order" element={
                <ProtectedRoutes>
                  <ConfirmOrder />
                  </ProtectedRoutes>
              } />

            <Route
              path="/payment_method" element={
                <ProtectedRoutes>
                  <PaymentMethod />
                  </ProtectedRoutes>
              } />

            <Route
              path="/me/orders" element={
                <ProtectedRoutes>
                  <MyOrders />
                  </ProtectedRoutes>
              } />

            <Route
              path="/me/order/:id" element={
                <ProtectedRoutes>
                  <OrderDetails />
                  </ProtectedRoutes>
              } />

            <Route
              path="/me/invoice/:id" element={
                <ProtectedRoutes>
                  <Invoice />
                  </ProtectedRoutes>
              } />

            <Route
              path="/me/review" element={
                <ProtectedRoutes>
                  <NewReview />
                  </ProtectedRoutes>
              } />

            <Route
              path="/order/success" element={
                <ProtectedRoutes>
                  <OrderSuccess />
                  </ProtectedRoutes>
              } />

     
    </>
  );
};

export default UserRoutes;