import io from 'socket.io-client';
import { store } from '../redux/store';
import { addChatNotification, addNotification } from '../redux/features/NotificationSlace';

let socket;

export const initSocket = (userId, isAdmin) => {
  if (socket && socket.connected) {
    console.log('Socket zaten bağlı, yeniden bağlanmaya gerek yok');
    return socket;
  }

  console.log('Yeni socket bağlantısı kuruluyor...');
 const SOCKET_URL = process.env.NODE_ENV === 'production' 
  ? 'https://www.ankapazarlama.com'
  : 'http://localhost:4000';

    socket = io(SOCKET_URL, {
    transports: ['websocket'],
    upgrade: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    withCredentials: true,
  });


  socket.on('connect', () => {
    console.log('Socket.IO bağlantısı kuruldu');
    socket.emit('authenticate', userId);
  });

  socket.on('disconnect', () => {
    console.log('Socket.IO bağlantısı kesildi');
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Socket.IO yeniden bağlandı, deneme sayısı: ${attemptNumber}`);
    socket.emit('authenticate', userId);
  });

  socket.on('newNotification', (notification) => {
    console.log('Yeni bildirim alındı:', notification);
    store.dispatch(addNotification(notification));
  });
   
  socket.on('newChatNotification', (notification) => {
    console.log('Yeni chat bildirimi alındı:', notification);
    store.dispatch(addChatNotification(notification));
  });

  // Hata yönetimi ekleyelim
  socket.on('connect_error', (error) => {
    console.error('Bağlantı hatası:', error);
  });

  socket.on('error', (error) => {
    console.error('Socket hatası:', error);
  });

  return socket;
};
export const deleteNotification = (userId, notificationId) => {
  if (socket) {
    socket.emit('deleteNotification', { userId, notificationId });
  } else {
    console.error('Socket bağlantısı kurulmamış. Bildirim silinemiyor.');
  }
};

export const markAsRead = (userId, notificationId) => {
  if (socket && socket.connected) {
    socket.emit('markAsRead', { userId, notificationId });
  } else {
    console.error('Socket bağlantısı kurulmamış. Bildirim okundu olarak işaretlenemiyor.');
  }
};

export const sendMessage = (senderId, receiverId, content) => {
  return new Promise((resolve, reject) => {
    if (socket && socket.connected) {
      socket.emit('sendMessage', { senderId, receiverId, content }, (response) => {
        if (response.success) {
          resolve(response.message);
        } else {
          reject(new Error(response.error));
        }
      });
    } else {
      reject(new Error('Socket bağlantısı yok'));
    }
  });
};

export const getSocket = () => socket;

export const getMessages = (userId, otherUserId, callback) => {
  if (socket && socket.connected) {
    console.log('Mesajlar isteniyor:', userId, otherUserId);
    socket.emit('getMessages', { userId, otherUserId });
    socket.once('loadMessages', (loadedMessages) => {
      console.log('Yüklenen mesajlar:', loadedMessages);
      callback(loadedMessages);
    });
  } else {
    console.error('Socket bağlantısı kurulmamış. Mesajlar yüklenemiyor.');
  }
};

export const closeSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    console.log('Socket.IO bağlantısı kapatıldı');
  }
};