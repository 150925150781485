import React from "react";
import MetaData from "../layout/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setCartItems, removeCartItem } from "../../redux/features/cartSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.cart);

  const increaseQty = (item) => {
    const newQty = item.quantity + 1;
    if (newQty > item.stock) return;
    addToCart(item, newQty);
  };

  const decreaseQty = (item) => {
    const newQty = item.quantity - 1;
    if (newQty <= 0) return;
    addToCart(item, newQty);
  };

  const addToCart = (item, newQty) => {
    const cartItem = {
      ...item,
      quantity: newQty,
    };
    dispatch(setCartItems(cartItem));
  };

  const removeCartItemHandler = (id) => {
    dispatch(removeCartItem(id));
  };

  const checkoutHandler = () => {
    navigate("/shipping");
  };

  return (
    <>
      <MetaData title={"Sepetiniz"} />
      {cartItems.length === 0 ? (
        <h2 className="mt-5">Sepetinizde ürün yok.</h2>
      ) : (
        <>
          <h2 className="mt-5">
            Sepetinizde: <b>{cartItems.length}</b> ürün var.
          </h2>
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-lg-8">
              {cartItems.map((item) => (
                <div key={item.id}>
                  <hr />
                  <div className="cart-item">
                    <div className="row w-100">
                      <div className="col-4 col-lg-3">
                        <img
                          src={item?.image}
                          alt="product"
                          className="product-image" // Sınıf ismini düzenledim
                        />
                      </div>
                      <div className="col-5 col-lg-3">
                        <Link to={`/product/${item.id}`}>{item.name}</Link>
                      </div>
                      <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                        <p id="card_item_price">{item?.price}₺</p>
                      </div>
                      <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                        <div className="stockCounter d-inline">
                          <span
                            className="btn btn-danger minus"
                            onClick={() => decreaseQty(item, item.quantity)}
                          >
                            -
                          </span>
                          <input
                            type="number"
                            className="form-control count d-inline"
                            value={item?.quantity}
                            readOnly
                          />
                          <span
                            className="btn btn-primary plus"
                            onClick={() => increaseQty(item, item.quantity)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div className="col-4 col-lg-1 mt-4 mt-lg-0">
                        <i
                          id="delete_cart_item"
                          className="fa fa-trash btn btn-danger"
                          onClick={() => removeCartItemHandler(item.id)}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
            <div className="col-12 col-lg-3 my-4">
              <div id="order_summary">
                <h4>Sepet Toplamı</h4>
                <hr />
                <p>
                  Toplam Miktar:{" "}
                  <span className="order-summary-values">
                    {cartItems.reduce((acc, item) => acc + item.quantity, 0)}{" "}
                    (Adet)
                  </span>
                </p>
                <p>
                  Genel Toplam:{" "}
                  <span className="order-summary-values">
                    {cartItems
                      .reduce(
                        (acc, item) => acc + item.quantity * item.price,
                        0
                      )
                      .toFixed(2)}{" "}
                    ₺
                  </span>
                </p>
                <hr />
                <button
                  id="checkout_btn"
                  className="btn btn-primary w-100"
                  onClick={checkoutHandler}
                >
                  Sipariş Ver
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
