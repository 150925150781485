import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const reviewApi = createApi({
  reducerPath: 'reviewApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
  }),
  endpoints: (builder) => ({
    createReview: builder.mutation({
      query: (body) => ({
        url: '/reviews',
        method: 'POST',
        body
      }),
    }),
    getSingleReview: builder.query({
      query: (id) => `/reviews/${id}`,
    }),
    getAllReviews: builder.query({
      query: () => `/reviews`,
    }),
    deleteReview: builder.mutation({
      query: (id) => ({
        url: `/reviews/${id}`,
        method: 'DELETE',
      }),
    }),
    updateReview: builder.mutation({
      query: ({ id, body }) => ({
        url: `/reviews/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    getSingleProductReviews: builder.query({
      query: (productId) => `/products/${productId}/reviews`,
    }),

    getProductReviews: builder.query({
      query: (productId) => `/reviews/product/${productId}`,
    }),

  }),
});

export const {
  useCreateReviewMutation,
  useGetSingleReviewQuery,
  useLazyGetAllReviewsQuery,
  useDeleteReviewMutation,
  useUpdateReviewMutation,
  useGetSingleProductReviewsQuery,
  useLazyGetProductReviewsQuery,
} = reviewApi;
