import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
  }),
  endpoints: builder => ({
    getAllCategories: builder.query({
      query: () => '/categories',
    }),

     getSingleCategory: builder.query({
      query: (id) => ({
        url: `/categories/${id}`,
      }),
     }),
      createCategory: builder.mutation({
    query: (category) => ({
      url: '/categories',
      method: 'POST',
      body: category,
    }),
      }),
       updateCategory: builder.mutation({
  query: ({ id, body }) => ({
    url: `/categories/${id}`,
    method: 'PATCH',
    body: body,
  }),
}),

      deleteCategory: builder.mutation({
        query: (id) => ({
          url: `/categories/${id}`,
          method: 'DELETE',
        }),
      }),
  }),
  
});


   
  

export const {
  useGetAllCategoriesQuery,
  useGetSingleCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation
} = categoriesApi;