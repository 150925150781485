import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const emailConfigApi = createApi({
  reducerPath: 'emailConfigApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: '/api/v1/',
    credentials: 'include', // CSRF token için gerekli
  }),
  tagTypes: ['EmailConfig'],
  endpoints: (builder) => ({
    getEmailConfig: builder.query({
      query: () => 'email-config',
      providesTags: ['EmailConfig'],
    }),
    updateEmailConfig: builder.mutation({
      query: (config) => ({
        url: 'email-config',
        method: 'PATCH',
        body: config,
      }),
      invalidatesTags: ['EmailConfig'],
    }),
  }),
});

export const { 
  useGetEmailConfigQuery, 
  useUpdateEmailConfigMutation 
} = emailConfigApi;