import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setUser, setIsAuthenticated, setLoading } from '../features/userSlice';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/users',
 prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: builder => ({
    showCurrentUser: builder.query({
      query: () => "/showMe",
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.user)); // Burada veriyi işleyip göndermekteyiz
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));

        } catch (error) {
          dispatch(setLoading(false));
          console.log(error);
        }
      },
      providesTags: ['User'],
    }),
    updateUser: builder.mutation({
    query: (data) => ({
      url: `/${data.id}`,
      method: "PATCH",
      body: data
    }),
   
    invalidatesTags: ['User'],
    }),

     uploadUserAvatar: builder.mutation({
    query: (data) => ({
      url: "/uploadUserAvatar",
      method: "POST",
      body: data
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        dispatch(setUser(data.user)); 
      } catch (error) {
        console.log(error);
      }
      },
    invalidatesTags: ['User'],
     }),
     
     updateUserPassword: builder.mutation({
  query: (data) => ({
    url: "/updateUserPassword",
    method: "PATCH",
    body: data
  }),
//  async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//     try {
//       const { data } = await queryFulfilled;
//       console.log('Şifre güncelleme yanıtı:', data);
//     } catch (error) {
//       console.error('Şifre güncelleme hatası:', error);
//     }
//   },
}),
     
     getAllUsers: builder.query({
      query: () => "/",
      providesTags: ['User'],
     }),
     
     deleteUser: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
     }),
     getSingleUser: builder.query({
      query: (id) => `/${id}`,
      providesTags: ['User'],
     }),
   getChatUsers: builder.query({
      query: () => "/chat-users",
      providesTags: ['User'],
    }),
    
  }),
})
  



   
  

export const {
  useShowCurrentUserQuery,
  useUpdateUserMutation,
  useUploadUserAvatarMutation,
  useUpdateUserPasswordMutation,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useGetSingleUserQuery,
  useGetChatUsersQuery
} = userApi;