import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateCategoryMutation, useGetSingleCategoryQuery } from '../../redux/api/categoriesApi';
import { toast } from 'react-hot-toast';
import AdminLayout from '../layout/AdminLayout';
import MetaData from "../layout/MetaData";

const UpdateCategory = () => {
  const navigate = useNavigate();
  const params = useParams();


  const [category, setCategory] = useState({
    id: "",
    name: "",
 
  });

  const { name, id} = category;

 const [updateCategory, { isLoading, error, isSuccess }] =
    useUpdateCategoryMutation();

  const { data } = useGetSingleCategoryQuery(params?.id);


  useEffect(() => {
  if (data?.category) {
    setCategory({
      id: data.category._id,
      name: data.category.name
    });
  }

   const handleUpdate = async () => {
    if (isSuccess) {
      toast.success("Kategori güncellendi");
      navigate("/admin/categories");
    }

    if (error) {
      toast.error(error?.data?.message || "Bir hata oluştu");
    }
  };

  handleUpdate(); // Mutasyon tamamlandığında veya hata oluştuğunda toast'ları göster

}, [error, isSuccess, navigate, data, updateCategory]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setCategory({
      ...category,
      [name]: value,
    });
  };

 const submitHandler = async (e) => {
  e.preventDefault();

  try {
    const result = await updateCategory({
      id: params?.id,
      body: category,
    }).unwrap();

    if (result.category) {
      toast.success("Kategori başarıyla güncellendi");
      navigate("/admin/categories");
    }
  } catch (err) {
    toast.error(err?.data?.message || "Kategori güncellenirken bir hata oluştu");
  }
};


  return (
    <>
    <AdminLayout>
      <MetaData title={"Kategori Güncelle"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Kategori Güncelle</h2>
            <div className="mb-3">
              <label htmlFor="name_field" className="form-label"> Kategori Adı </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

           
            
            <button type="submit" className="btn w-100 py-2" disabled={isLoading}>
              {isLoading ? "Güncelleniyor..." : "Kategoriyi Güncelle"}
            </button>
          </form>
        </div>
        </div>
        </AdminLayout>
    </>
  );
};

export default UpdateCategory;
