import React, { useState, useEffect } from 'react';
import { useResetPasswordMutation } from '../../redux/api/authApi';
import { toast } from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MetaData from '../layout/MetaData';

const ResetPassword = () => {
  const [resetPassword, { isLoading, error, isSuccess }] = useResetPasswordMutation();
  const [password, setPassword] = useState('');
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  // URL parametrelerini al
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  const submitHandler = async (e) => {
    e.preventDefault();
    const resetPasswordData = {
      email,
      token,
      password,
    };

    resetPassword(resetPasswordData);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/login');
    }
    if (error) {
      toast.error(error?.data?.msg || 'Bilinmeyen bir hata oluştu.');
    }

   
  }, [error, isAuthenticated, navigate]);

  useEffect(() => {
       if (isSuccess) {
         toast.success('Şifreniz başarıyla değiştirildi.');
          navigate('/login');
    }
  }, [isSuccess, navigate]);

  return (
    <>
      <MetaData title={"Şifre Yenile"} />
    <div className="row wrapper">
      <div className="col-10 col-lg-5">
        <form className="shadow rounded bg-body" onSubmit={submitHandler}>
          <h2 className="mb-4">Yeni Şifre</h2>

          <div className="mb-3">
            <label htmlFor="password_field" className="form-label">Password</label>
            <input
              type="password"
              id="password_field"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button id="new_password_button" type="submit" className="btn w-100 py-2" disabled={isLoading}>
            {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Yeni Şifre Oluştur'}
          </button>
        </form>
      </div>
      </div>

    </>
  );
};

export default ResetPassword;
