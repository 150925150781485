import React, { useEffect, useState } from 'react';
import { useLoginMutation } from '../../redux/api/authApi';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import MetaData from '../layout/MetaData';
const Login = () => {
const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, { isLoading, error, data }] = useLoginMutation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (error) {
  
    toast.error(error?.data?.msg || 'Bilinmeyen bir hata oluştu.');
  }
}, [error]);


  const submitHandler = (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password
    }
    login(loginData)
    navigate('/')
  };


  return (
    <>
      <MetaData title={"Giriş Yap"} />
     <div className="row wrapper">
      <div className="col-10 col-lg-5">
        <form
          className="shadow rounded bg-body"
          onSubmit={submitHandler}
        >
          <h2 className="mb-4">Giriş Yap</h2>
          <div className="mb-3">
            <label htmlFor="email_field" className="form-label">E-Posta</label>
            <input
              type="email"
              id="email_field"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password_field" className="form-label">Şifre</label>
            <input
              type="password"
              id="password_field"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <a href="/password/forgot" className="float-end mb-4">Şifremi Unuttum?</a>

          <button id="login_button" type="submit" className="btn w-100 py-2" disabled={isLoading}>
            {isLoading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
          </button>

          <div className="my-3">
            <a href="/register" className="float-end">Yeni Hesap Oluştur?</a>
          </div>
        </form>
      </div>
      </div>
    </>
  );
};


export default Login;