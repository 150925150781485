import React, { useEffect } from 'react';
import MetaData from "../layout/MetaData";
import { useGetSingleOrderQuery } from "../../redux/api/orderApi";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from './../Loader';


const OrderDetails = () => {
  const params=useParams();
  const { data, isLoading, error } = useGetSingleOrderQuery(params?.id);
  const order = data?.order || {};
  const { shippingInfo, orderStatus, paymentInfo, totalAmount, orderItems } = order;
  
 
useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
  }, [error]);


if (isLoading) return <Loader/>;

  return (
    <>
      <MetaData title={"Sipariş Detayı"} />
     <div className="row d-flex justify-content-center">
      <div className="col-12 col-lg-9 mt-5 order-details">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mt-5 mb-4">Sipariş Detayı</h3>
          <Link className="btn btn-success" to={`/me/invoice/${order._id}`}>
            <i className="fa fa-print"></i> Siparişi Yazdır
          </Link>
        </div>
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <th scope="row">Sipariş No</th>
              <td>{order._id}</td>
            </tr>
            <tr>
              <th scope="row">Sipariş Durumu</th>
              <td className={String(order.status) === "teslim edildi" ? "text-success" : "text-danger"}>
                <b>{order.status}</b>
              </td>
            </tr>
            <tr>
              <th scope="row">Tarih</th>
             <td>
  {new Date(order?.createdAt).toLocaleString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })}
</td>
            </tr>
          </tbody>
        </table>

        <h3 className="mt-5 mb-4">Teslimat Bilgileri</h3>
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <th scope="row">Adı-Soyadı</th>
              <td>{order.user?.name}</td>
            </tr>
            <tr>
              <th scope="row">Telefon</th>
              <td>{shippingInfo?.phoneNo}</td>
            </tr>
            <tr>
              <th scope="row">Adres</th>
              <td>{`${shippingInfo?.address}, ${shippingInfo?.state}, ${shippingInfo?.city},  ${shippingInfo?.country}`}</td>
            </tr>
          </tbody>
        </table>

        <h3 className="mt-5 mb-4">Ödeme Bilgileri</h3>
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <th scope="row">Ödeme Durumu</th>
              <td className="greenColor">
                <b>{paymentInfo?.status}</b>
              </td>
            </tr>
            <tr>
              <th scope="row">Ödeme Şekli</th>
              <td>{order.paymentMethod}</td>
            </tr>
            {/* <tr>
              <th scope="row">Stripe ID</th>
              <td>stripe-id</td>
            </tr> */}
            <tr>
            <th scope="row">Ödeme Tutarı</th>
             <td>{parseFloat(totalAmount).toFixed(2)}₺</td>
            </tr>
          </tbody>
        </table>

        <h3 className="mt-5 my-4">Sipariş Kalemleri:</h3>

        <hr />
          <div className="cart-item my-1">
            {orderItems?.map((item) => (
              <div className="row my-5">
                <div className="col-4 col-lg-2">
                  <img
                    src={item?.image}
                    alt={item?.name}
                    height="45"
                    width="65"
                  />
                </div>

                <div className="col-5 col-lg-5">
                  <Link to={`/product/${item?.product}`}>{item?.name}</Link>
                </div>

                <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                  <td>{parseFloat(item?.price).toFixed(2)}₺</td>
                </div>

                <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                  <p>{item?.quantity} Adet</p>
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};
export default OrderDetails;