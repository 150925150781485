import React from 'react';
import { useGetSingleProductReviewsQuery } from '../../redux/api/reviewApi';
import MetaData from '../layout/MetaData';
import StarRatings from 'react-star-ratings';

const ListReviews = ({ productId }) => {
  const { data, error, isLoading } = useGetSingleProductReviewsQuery(productId);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error.data.msg}</p>;

  const reviews = data?.review || [];

  return (
    <>
      <MetaData title={"Yorumlar"} />
      <div className="reviews w-75">
        <h3>Yorumlar:</h3>
        <hr />
        {reviews.map((review) => (
          <div key={review._id} className="review-card my-3">
            <div className="row">
              <div className="col-1">
                <img
                  src={
                    review.user?.profilePic ? review.user?.profilePic?.url : "/images/default_avatar.jpg"
                  }
                  alt={review.user?.name || "User"}
                  width="50"
                  height="50"
                  className="rounded-circle"
                />
              </div>
              <div className="col-11">
                <StarRatings
                  rating={review?.rating}
                  starRatedColor="#ffb829"
                  numberOfStars={5}
                  name="rating"
                  starDimension="24px"
                  starSpacing="1px"
                />
                <p className="review_user">{review?.user?.name}</p>
                <p className="review_comment">{review?.comment}</p>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    </>
  );
};

export default ListReviews;
