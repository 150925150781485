import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import MetaData from '../layout/MetaData';
import { useGetOrCreateCompanyInfoQuery, useUpdateCompanyInfoMutation } from '../../redux/api/companyInfoApi';
import AdminLayout from '../layout/AdminLayout';

const CompanyInfoForm = () => {
  const { data: existingCompanyInfo, isLoading } = useGetOrCreateCompanyInfoQuery();
  const [updateCompanyInfo] = useUpdateCompanyInfoMutation();

 const [logoPreview, setLogoPreview] = useState(null);
const [invoiceLogoPreview, setInvoiceLogoPreview] = useState(null);

  

  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    bank1: { bankName: '', accountHolder: '', bankAccountNumber: '', bankIBAN: '' },
    bank2: { bankName: '', accountHolder: '', bankAccountNumber: '', bankIBAN: '' },
    whatsapp: '',
    twitter: '',
    instagram: '',
    footerText: '',
  });

  
  const [logo, setLogo] = useState(null);
  const [invoiceLogo, setInvoiceLogo] = useState(null);

  useEffect(() => {
  if (existingCompanyInfo && existingCompanyInfo.companyInfo) {
    setCompanyInfo(existingCompanyInfo.companyInfo);
    if (existingCompanyInfo.companyInfo.logo) {
      setLogoPreview(existingCompanyInfo.companyInfo.logo);
    }
    if (existingCompanyInfo.companyInfo.invoice_logo) {
      setInvoiceLogoPreview(existingCompanyInfo.companyInfo.invoice_logo);
    }
  }
}, [existingCompanyInfo]);

  const handleInputChange = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };

  const handleBankInputChange = (bankNumber, e) => {
    setCompanyInfo({
      ...companyInfo,
      [bankNumber]: { ...companyInfo[bankNumber], [e.target.name]: e.target.value }
    });
  };

  const handleFileChange = (e, setterFunction, previewSetterFunction) => {
  if (e.target.files[0]) {
    setterFunction(e.target.files[0]);
    previewSetterFunction(URL.createObjectURL(e.target.files[0]));
  }
};

  const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const formData = new FormData();
    Object.keys(companyInfo).forEach(key => {
      if (key === 'bank1' || key === 'bank2') {
        formData.append(key, JSON.stringify(companyInfo[key]));
      } else {
        formData.append(key, companyInfo[key]);
      }
    });
    if (logo) formData.append('logo', logo);
    if (invoiceLogo) formData.append('invoice_logo', invoiceLogo);

    const result = await updateCompanyInfo(formData).unwrap();
    if (result.companyInfo) {
      toast.success('Firma bilgileri başarıyla güncellendi.');
    } else {
      toast.error('Firma bilgileri güncellenirken bir hata oluştu.');
    }
  } catch (error) {
    console.error('Error:', error);
    toast.error(error.data?.message || 'Bir hata oluştu. Lütfen tekrar deneyin.');
  }
};

  if (isLoading) return <div>Yükleniyor...</div>;

  return (
    <>
    <AdminLayout>
      <MetaData title="Firma Bilgileri" />
      
      <div className="company-info-form-container">
        <form className="company-info-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Firma Adı</label>
            <input id="name" className="form-control" type="text" name="name" value={companyInfo.name} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="address">Adres</label>
            <input id="address" className="form-control" type="text" name="address" value={companyInfo.address} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefon</label>
            <input id="phone" className="form-control" type="tel" name="phone" value={companyInfo.phone} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-posta</label>
            <input id="email" className="form-control" type="email" name="email" value={companyInfo.email} onChange={handleInputChange} />
          </div>

          <div className="bank-info">
            <h3>Banka Bilgileri 1</h3>
            <div className="form-group">
              <label htmlFor="bank1-name">Banka Adı</label>
              <input id="bank1-name" className="form-control" type="text" name="bankName" value={companyInfo.bank1.bankName} onChange={(e) => handleBankInputChange('bank1', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="bank1-accountHolder">Hesap Sahibi</label>
              <input id="bank1-accountHolder" className="form-control" type="text" name="accountHolder" value={companyInfo.bank1.accountHolder} onChange={(e) => handleBankInputChange('bank1', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="bank1-accountNumber">Hesap Numarası</label>
              <input id="bank1-accountNumber" className="form-control" type="text" name="bankAccountNumber" value={companyInfo.bank1.bankAccountNumber} onChange={(e) => handleBankInputChange('bank1', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="bank1-iban">IBAN</label>
              <input id="bank1-iban" className="form-control" type="text" name="bankIBAN" value={companyInfo.bank1.bankIBAN} onChange={(e) => handleBankInputChange('bank1', e)} />
            </div>
          </div>

          <div className="bank-info">
            <h3>Banka Bilgileri 2</h3>
            <div className="form-group">
              <label htmlFor="bank2-name">Banka Adı</label>
              <input id="bank2-name" className="form-control" type="text" name="bankName" value={companyInfo.bank2.bankName} onChange={(e) => handleBankInputChange('bank2', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="bank2-accountHolder">Hesap Sahibi</label>
              <input id="bank2-accountHolder" className="form-control" type="text" name="accountHolder" value={companyInfo.bank2.accountHolder} onChange={(e) => handleBankInputChange('bank2', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="bank2-accountNumber">Hesap Numarası</label>
              <input id="bank2-accountNumber" className="form-control" type="text" name="bankAccountNumber" value={companyInfo.bank2.bankAccountNumber} onChange={(e) => handleBankInputChange('bank2', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="bank2-iban">IBAN</label>
              <input id="bank2-iban" className="form-control" type="text" name="bankIBAN" value={companyInfo.bank2.bankIBAN} onChange={(e) => handleBankInputChange('bank2', e)} />
            </div>
          </div>

          <div className="social-media-info">
            <h3>Sosyal Medya</h3>
            <div className="form-group">
              <label htmlFor="whatsapp">WhatsApp</label>
              <input id="whatsapp" className="form-control" type="text" name="whatsapp" value={companyInfo.whatsapp} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="twitter">Twitter</label>
              <input id="twitter" className="form-control" type="text" name="twitter" value={companyInfo.twitter} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="instagram">Instagram</label>
              <input id="instagram" className="form-control" type="text" name="instagram" value={companyInfo.instagram} onChange={handleInputChange} />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="footerText">Alt Bilgi Metni</label>
            <textarea id="footerText" className="form-control" name="footerText" value={companyInfo.footerText} onChange={handleInputChange} />
          </div>

         <div className="form-group">
  <label htmlFor="logo">Site Logo</label>
  <input
    type="file"
    id="logo"
    onChange={(e) => handleFileChange(e, setLogo, setLogoPreview)}
    accept="image/*"
  />
  {logoPreview && (
    <img src={logoPreview} alt="Site Logo Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />
  )}
</div>
<div className="form-group">
  <label htmlFor="invoice_logo">Fatura Logo</label>
  <input
    type="file"
    id="invoice_logo"
    onChange={(e) => handleFileChange(e, setInvoiceLogo, setInvoiceLogoPreview)}
    accept="image/*"
  />
  {invoiceLogoPreview && (
    <img src={invoiceLogoPreview} alt="Invoice Logo Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />
  )}
</div>
          <div className="button-group">
            <button type="submit" className="btn btn-primary">Kaydet</button>
          </div>
        </form>
      </div>
      </AdminLayout>
    </>
  );
};

export default CompanyInfoForm;
