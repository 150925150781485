import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
    credentials: 'include', // Tarayıcının cookie'leri isteğe eklemesi için
  }),
  
  endpoints: builder => ({
    createOrder: builder.mutation({
      query: (body) => ({
        url: '/orders',
        method: 'POST',
        body,
      }),
    }),
    getCurrentUserOrders: builder.query({
      query: () => '/orders/showAllMyOrders',
    }),
     getSingleOrder: builder.query({
      query: (id) => `/orders/${id}`,
     }),
     
     getSales: builder.query({
      query: ({ startDate, endDate }) =>
        `/orders/admin/get_sales/?startDate=${startDate}&endDate=${endDate}`,
     }),
     getAllOrders: builder.query({
      query: () => '/orders',
     }),
     updateOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body,
      }),
     }),
     deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}`,
        method: 'DELETE',
      }),
     }),

  }),
});

export const {
  useCreateOrderMutation,
  useGetCurrentUserOrdersQuery,
  useGetSingleOrderQuery,
  useLazyGetSalesQuery,
  useGetAllOrdersQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
} = orderApi;
