import React, { useState, useCallback, useRef } from 'react';
import { useImportProductsMutation, useExportProductsQuery, useGetAllProductsQuery } from '../../redux/api/productsApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../layout/AdminLayout';
import MetaData from '../layout/MetaData';
const ProductImportExport = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [importProducts, { isLoading: isImporting }] = useImportProductsMutation();
  const { refetch: exportProducts, isFetching: isExporting } = useExportProductsQuery();
  
  const { refetch: refetchProducts } = useGetAllProductsQuery();

  

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || selectedFile.type === 'application/vnd.ms-excel')) {
      setFile(selectedFile);
    } else {
      toast.error('Lütfen geçerli bir Excel dosyası seçin.');
      e.target.value = null;
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImport = async () => {
    if (!file) {
      toast.error('Lütfen bir dosya seçin.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const result = await importProducts(formData).unwrap();
      if (result.success) {
        toast.success(result.message);
        setFile(null);
        if (fileInputRef.current) fileInputRef.current.value = null;
        await refetchProducts();
      } else {
        throw new Error(result.message || 'Fiyat güncelleme işlemi başarısız oldu.');
      }
    } catch (error) {
      console.error('Fiyat güncelleme hatası:', error);
      toast.error(`Hata: ${error.data?.message || error.message || 'Ürün fiyatları güncellenemedi.'}`);
    }
  };

  const handleExport = useCallback(async () => {
    try {
      const response = await exportProducts();
      if (response?.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'urun_fiyatlari.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success('Fiyat listesi başarıyla indirildi');
      } else if (response?.error) {
        throw new Error(response.error.data?.message || 'Fiyat listesi indirme işlemi başarısız oldu.');
      }
    } catch (error) {
      console.error('Fiyat listesi indirme hatası:', error);
      toast.error(`Fiyat listesi indirme hatası: ${error.message}`);
    }
  }, [exportProducts]);

  return (
    <>
      <AdminLayout>
      <MetaData title={"Ürün Fiyat Güncelleme"} />
    <div className="product-import-export">
      <h2>Ürün Fiyat Güncelleme</h2>
      <div className="button-group">
        <div className="file-input-wrapper">
          <input 
            type="file" 
            ref={fileInputRef}
            onChange={handleFileChange} 
            accept=".xlsx, .xls" 
            style={{display: 'none'}}
          />
          <button onClick={handleFileButtonClick} disabled={isImporting}>Dosya Seç</button>
          {file && <span>{file.name}</span>}
        </div>
        <button onClick={handleImport} disabled={!file || isImporting} className="btn btn-primary">
          {isImporting ? 'Fiyatlar Güncelleniyor...' : 'Fiyatları Güncelle'}
        </button>
        <button onClick={handleExport} disabled={isExporting} className="btn btn-secondary">
          {isExporting ? 'İndiriliyor...' : 'Fiyat Listesini İndir'}
        </button>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
    
          <p>Ürün fiyatlarınızı buradan güncelleyebilirsiniz.</p>
          <p>Önce "Fiyat Listesini İndir" butonuna tıklayınız. Daha sonra indirdiğiniz excel dosyasını düzenleyiniz.</p>
          <p>"Fiyatları Güncelle" butonuna tıklayınız ve daha evvel fiyatlarını güncellediğiniz dosyayı seçerek güncellemeyi yapınız.</p>
        </div>
        
    </AdminLayout>
    </>
  );
};

export default ProductImportExport;