import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setIsAuthenticated } from '../features/userSlice';
import { userApi } from './userApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/auth',
  }),
  endpoints: builder => ({
    login: builder.mutation({
  query(body) {
    return {
      url: '/login',
      method: 'POST',
      body
    }
  },
  async onQueryStarted(arg, { dispatch, queryFulfilled }) {
     try {
       const { data } = await queryFulfilled;
       dispatch(userApi.endpoints.showCurrentUser.initiate(null));
       dispatch(setIsAuthenticated(true));
     } catch (error) {
       console.log(error);
     }
   }
}),


    logout: builder.mutation({
  query: () => ({
    url: '/logout',
    method: 'DELETE',
  }),
    }),
    
    register: builder.mutation({
      query(body) {
        return {
          url: '/register',
          method: 'POST',
          body
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
         await dispatch(userApi.endpoints.showCurrentUser.initiate(null));
          dispatch(setIsAuthenticated(true));
        } catch (error) {
          console.log(error);
        }
      }
    }),
    

     forgotPassword: builder.mutation({
      query(body) {
        return {
          url: '/forgot-password',
          method: 'POST',
          body
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
         await dispatch(userApi.endpoints.showCurrentUser.initiate(null));
          dispatch(setIsAuthenticated(true));
        } catch (error) {
          console.log(error);
        }
      }
     }),

      resetPassword: builder.mutation({
      query(body) {
        return {
          url: '/reset-password',
          method: 'POST',
          body
        }
      },
    }),

    verifyEmail: builder.mutation({
      query(body) {
        return {
          url: '/verify-email',
          method: 'POST',
          body
        }
      }
    }),
    
  }),
});

   
  

export const { useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
} = authApi;