import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const iyzipayInfoApi = createApi({
  reducerPath: 'iyzipayInfoApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  tagTypes: ['IyzipayInfo'],
  endpoints: (builder) => ({
    getSingleIyzipayInfo: builder.query({
      query: (id) => `iyzipay-info/${id}`,
      providesTags: ['IyzipayInfo'],
    }),
    updateIyzipayInfo: builder.mutation({
      query: (data) => ({
        url: `iyzipay-info/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['IyzipayInfo'],
    }),
    createIyzipayInfo: builder.mutation({
      query: (data) => ({
        url: 'iyzipay-info',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['IyzipayInfo'],
    }),
    deleteIyzipayInfo: builder.mutation({
      query: (id) => ({
        url: `iyzipay-info/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetSingleIyzipayInfoQuery,
  useUpdateIyzipayInfoMutation,
  useCreateIyzipayInfoMutation,
  useDeleteIyzipayInfoMutation,
} = iyzipayInfoApi;