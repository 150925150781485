import React from "react";
import UserLayout from "../layout/UserLayout";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import moment from 'moment';

const Profile = () => {
  const { user } = useSelector((state) => state.auth);
  
  
  return (
    <UserLayout>
      <MetaData title={"Profiliniz"} />
      <div className="row justify-content-around mt-5 user-info">
        <div className="col-12 col-md-3">
          <figure className="avatar avatar-profile">
            <img
              className="rounded-circle img-fluid"
              src={
                user?.profilePic ? user?.profilePic?.url : "/images/default_avatar.jpg"
              }
              alt={user?.name}
            />
          </figure>
        </div>

        <div className="col-12 col-md-5">
          <h4>Adı Soyadı</h4>
          <p>{user?.name}</p>

          <h4>E-Posta Adresi</h4>
          <p>{user?.email}</p>

          <h4>Kayıt Tarihi</h4>
          <p>{moment(user?.verified).format('DD-MM-YYYY')}</p>
        </div>
      </div>
    </UserLayout>
  );
};

export default Profile;
