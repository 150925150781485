import React, { useState, useEffect } from 'react';
import { useForgotPasswordMutation } from '../../redux/api/authApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import MetaData from '../layout/MetaData';

const ForgotPassword = () => {

const [email, setEmail] = useState('');
  const [forgotPassword, { isLoading, error, isSuccess }] = useForgotPasswordMutation();
  const {isAuthenticated}= useSelector((state) => state.auth);
  const navigate = useNavigate();
  

  const submitHandler = async (e) => {
    e.preventDefault();
    const forgotPasswordData = {
      email,
    }

    await forgotPassword(forgotPasswordData);

  };

  useEffect(() => {
   if(isAuthenticated){
    navigate('/');
    }

    if (error) {
      toast.error(error?.data?.msg || 'Bilinmeyen bir hata oluştu.');
    }

  }, [error, isAuthenticated, navigate]);


useEffect(() => {
  if (isSuccess) {
    toast.success('Sıfırlama linki e-posta adresinize gönderildi.');
  }
}, [isSuccess]);
  

  return (
<>
      <MetaData title={"Şifremi Unuttum"} />
     <div className="row wrapper">
      <div className="col-10 col-lg-5">
        <form
          className="shadow rounded bg-body"
          onSubmit={submitHandler}
        >
          <h2 className="mb-4">Şifremi Unuttum</h2>
          <div className="mt-3">
            <label htmlFor="email_field" className="form-label">E-Posta Adresinizi Giriniz</label>
            <input
              type="email"
              id="email_field"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button
            id="forgot_password_button"
            type="submit"
            className="btn w-100 py-2"
            disabled={isLoading}
          >
            {isLoading ? 'Yükleniyor...' : 'Sıfırlama Adresi Gönder'}
          </button>
        </form>
      </div>
      </div>
</>
  );
};

export default ForgotPassword;