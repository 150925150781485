import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
    tagTypes: ['Products'],
  }),
  
  endpoints: builder => ({
    getAllProducts: builder.query({
      query: (params) => ({
        url: "/products",
        params: { 
          page: params?.page,
          keyword: params?.keyword,
          category: params?.category,
          "price[gte]": params?.min,
          "price[lte]": params?.max,
          "averageRating[gte]": params?.ratings
         
        },
      }),
    }),

    
     getSingleProduct: builder.query({
       query: (id) => `/products/${id}`,
       providesTags: ['Products'],
     }),
      canUserReview: builder.query({
      query: (productId) => `/products/can_review/?productId=${productId}`,
    }),
     
      getAdminProducts: builder.query({
        query: () => `/products/admin/products`,
        providesTags: ['Products'],
      }),

      createProduct: builder.mutation({
      query(body) {
        return {
          url: "/products",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Products"],
      }),
      
      updateProduct: builder.mutation({
  query(body) {
    return {
      url: `/products/${body.id}`,
      method: "PATCH",
      body,
    };
  },
  invalidatesTags: ["Products"],
      }),

      uploadImage: builder.mutation({
        query(body) {
          return {
            url: `/products/uploadImage/${body.id}`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["Products"],
      }),
 deleteProductImage: builder.mutation({
      query({ id, body }) {
        return {
          url: `/products/${id}/delete_image`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["Product"],
 }),
 
 deleteProduct: builder.mutation({
      query(id) {
        return {
          url: `/products/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Product"],
 }),
  featuredProducts: builder.query({
      query: () => `/products/featuredProducts`,
  }),
   importProducts: builder.mutation({
      query: (formData) => ({
        url: '/products/import',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Products'],
    }),

    exportProducts: builder.query({
      query: () => ({
        url: '/products/export',
        responseHandler: (response) => response.blob(),
      }),
    }),

 
  }),
  
});

  


export const { 
  useGetAllProductsQuery,
  useGetSingleProductQuery,
  usegetSingleProductQuery,
  useCanUserReviewQuery,
  useGetAdminProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useUploadImageMutation,
  useDeleteProductImageMutation,
  useDeleteProductMutation,
  useFeaturedProductsQuery,
  useImportProductsMutation,
  useExportProductsQuery,
  useGetProductTemplateQuery,

} = productsApi;