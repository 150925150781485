import React from "react";
import HeroSection from "./HeroSection.jsx";
import iphone from '../../assets/lavabo_telefonlu.jpg'
import mac from '../../assets/banyo_dekor.jpg'
import FeaturedProduct from "./FeaturedProduct.jsx";

const HomePage = () => {
    return (
        <div className="home_page">
            <HeroSection
                title='En güzel su armatürleri'
                subTitle='En iyileri, sizin için'
                link='/store_products'
                image={iphone}
            />
            <div className="container">
                <FeaturedProduct />
            </div>
            <HeroSection
                title='Yaşayan Banyolar'
                subTitle='En güzel ürünler'
                link='/store_products'
                image={mac}
            />
        </div>
    );
};

export default HomePage;
