import React, { useEffect } from 'react';
import './styless/main.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import { Toaster } from 'react-hot-toast';
import useUserRoutes from './components/routes/UserRoutes';
import useAdminRoutes from './components/routes/AdminRoutes';
import NotFound from './components/layout/NotFound';
import { initSocket, closeSocket } from './services/socketService';

function App() {
  const userRoutes = useUserRoutes();
  const adminRoutes = useAdminRoutes();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user._id) {
      initSocket(user._id);
    }
    return () => {
      closeSocket();
    };
  }, [user]);

  return (
    <Router>
      <div className="App">
        <Toaster position="top-center" />
        <Header />

        <div className="container">
          <Routes>
            {userRoutes}
            {adminRoutes}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;