import ProductCardSkeleton from "../product/ProductCardSkeleton.jsx";
import { useFeaturedProductsQuery } from "../../redux/api/productsApi.js";
import ProductItem from "../product/ProductItem.jsx";

const skeletons = [1, 2, 3];

const FeaturedProduct = () => {
  const { data: products, isLoading, error } = useFeaturedProductsQuery();

  const errorMessage = error ? (error.message || 'Bir hata oluştu') : null;

  return (
    <div>
      <section className='featured_products'>
        <h2 className='section_title'>Öne Çıkan Ürünler</h2>
        <div className=' featured_products_list'>
          {errorMessage && <em className='form_error'>{errorMessage}</em>}
          {products && products.map((product) => (
            <ProductItem
              key={product._id}
              id={product._id}
              product={product}
              columnSize={4}
            />
          ))}
          {isLoading && skeletons.map((n) => <ProductCardSkeleton key={n} />)}
        </div>
      </section>
    </div>
  );
};

export default FeaturedProduct;