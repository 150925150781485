import React, { useState, useEffect } from 'react';
import { useGetSingleIyzipayInfoQuery, useUpdateIyzipayInfoMutation, useCreateIyzipayInfoMutation } from '../../redux/api/iyzipayInfoApi';
import { toast } from 'react-toastify';
import AdminLayout from '../layout/AdminLayout';
import MetaData from '../layout/MetaData';

const IyzipayInfoManager = () => {
  const [formData, setFormData] = useState({ apiKey: '', secretKey: '', baseUrl: '' });
  const { data: iyzipayInfo, isLoading, isError, error } = useGetSingleIyzipayInfoQuery();
  const [updateIyzipayInfo] = useUpdateIyzipayInfoMutation();
  const [createIyzipayInfo] = useCreateIyzipayInfoMutation();

 


  useEffect(() => {
    if (iyzipayInfo) {
      setFormData(iyzipayInfo);
    }
  }, [iyzipayInfo]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (iyzipayInfo) {
        await updateIyzipayInfo(formData).unwrap();
        toast.success('Iyzipay bilgileri güncellendi');
      } else {
        await createIyzipayInfo(formData).unwrap();
        toast.success('Iyzipay bilgileri oluşturuldu');
      }
    } catch (err) {
      toast.error(err?.data?.msg || 'Bir hata oluştu');
    }
  };

  if (isLoading) return <div className="iyzipay-info-manager">Yükleniyor...</div>;
  if (isError && error?.status !== 404) return <div className="iyzipay-info-manager">Bir hata oluştu: {error?.data?.msg}</div>;

  return (
    <>
  <AdminLayout>
  <MetaData title={"Iyzipay Bilgilerini Güncelle"} />
  <div className="iyzipay-info-manager container mt-4">
    <h2 className="mb-4">{iyzipayInfo ? 'Iyzipay Bilgilerini Güncelle' : 'Yeni Iyzipay Bilgileri Oluştur'}</h2>
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="apiKey" className="form-label">API Anahtarı</label>
          <input
            type="text"
            className="form-control"
            id="apiKey"
            name="apiKey"
            value={formData.apiKey}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="secretKey" className="form-label">Gizli Anahtar</label>
          <input
            type="text"
            className="form-control"
            id="secretKey"
            name="secretKey"
            value={formData.secretKey}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="baseUrl" className="form-label">Base URL</label>
        <input
          type="text"
          className="form-control"
          id="baseUrl"
          name="baseUrl"
          value={formData.baseUrl}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">
        {iyzipayInfo ? 'Güncelle' : 'Oluştur'}
      </button>
    </form>
   
  </div>
  </AdminLayout>
  </>
);
};

export default IyzipayInfoManager;
