import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const HeroSection = ({title, subTitle, link, image}) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        setIsAnimating(true);
        setTimeout(() => {
            navigate(link);
        }, 1000); // 1 saniye sonra yönlendir
    };

    return (
        <div className={`hero-container ${isAnimating ? 'animating' : ''}`}>
            <section className='hero_section'>
                <div className="align_center">
                    <h2 className='hero_title'>{title}</h2>
                    <p className='hero_subtitle'>{subTitle}</p>
                    <button onClick={handleClick} className='hero_link'>Ürünlere Git</button>
                </div>
                <div className="align_center">
                    <img src={image} alt="ürün" className='hero_image' />
                </div>
            </section>
        </div>
    );
};

export default HeroSection;