import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const companyInfoApi = createApi({
  reducerPath: 'companyInfoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
  }),
  endpoints: builder => ({
    getOrCreateCompanyInfo: builder.query({
      query: () => '/company-info',
    }),
    updateCompanyInfo: builder.mutation({
      query: (companyInfo) => ({
        url: '/company-info',
        method: 'PATCH',
        body: companyInfo,
      }),
    }),
    getCompanyLogo: builder.query({
      query: () => 'company-info/logo',
    }),
  }),
});

export const {
    useGetOrCreateCompanyInfoQuery,
  useUpdateCompanyInfoMutation,
  useGetCompanyLogoQuery,
} = companyInfoApi;