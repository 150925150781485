import React, { useState, useEffect } from 'react';
import AdminLayout from '../layout/AdminLayout';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SalesChart from '../charts/SalesCharts';
import { useLazyGetSalesQuery } from '../../redux/api/orderApi';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import MetaData from './../layout/MetaData';
import { tr } from 'date-fns/locale';

const Dashboard = () => {
 const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date());

  const [getSales, { error, isLoading, data }] =
    useLazyGetSalesQuery();

 useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }

    if (startDate && endDate && !data) {
      getSales({
        startDate: new Date(startDate).toISOString(),
        endDate: endDate.toISOString(),
      });
    }
  }, [error, getSales, startDate, endDate, data]);

  const submitHandler = () => {
    getSales({
      startDate: new Date(startDate).toISOString(),
      endDate: endDate.toISOString(),
    });
  };

  if (isLoading) return <Loader />;
  return (
    <AdminLayout>
      <MetaData title={"Admin Products"} />
      <div className="d-flex justify-content-start align-items-center">
        <div className="mb-3 me-4">
          <label className="form-label d-block">Başlangıç Tarihi</label>
         <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="form-control"
          dateFormat="dd.MM.yyyy" // Tarih formatı Türkiye'ye uygun olarak ayarlandı
          locale={tr} // Türkçe yerel ayarları kullanılıyor
/>

        </div>
        <div className="mb-3">
          <label className="form-label d-block">Bitiş Tarihi</label>

         <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="form-control"
          dateFormat="dd.MM.yyyy" // Tarih formatı Türkiye'ye uygun olarak ayarlandı
          locale={tr} // Türkçe yerel ayarları kullanılıyor
/>
        </div>
        <button
          className="btn fetch-btn ms-4 mt-3 px-5"
          onClick={submitHandler}
        >
          Getir
        </button>
      </div>

      <div className="row pr-4 my-5">
        <div className="col-xl-6 col-sm-12 mb-3">
          <div className="card text-white bg-success o-hidden h-100">
            <div className="card-body">
              <div className="text-center card-font-size">
                Satışlar
                <br />
                <b>{data?.totalSales?.toFixed(2)}₺</b>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-sm-12 mb-3">
          <div className="card text-white bg-danger o-hidden h-100">
            <div className="card-body">
              <div className="text-center card-font-size">
                Siparişler
                <br />
                <b>{data?.totalNumOrders}</b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SalesChart salesData={data?.sales} />

      <div className="mb-5"></div>
    </AdminLayout>
  );
};

export default Dashboard;
