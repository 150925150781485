import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPriceQueryParams } from '../../helpers/helpers';
import { useGetAllCategoriesQuery } from '../../redux/api/categoriesApi';
import StarRatings from 'react-star-ratings';

const Filters = () => {
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const { data } = useGetAllCategoriesQuery();
  const categories = data?.categories || [];

  useEffect(() => {
    searchParams.has('min') && setMin(searchParams.get('min'));
    searchParams.has('max') && setMax(searchParams.get('max'));
  }, [searchParams]);

  const handleButtonClick = (e) => {
    e.preventDefault();
  
    searchParams = getPriceQueryParams(searchParams, 'min', min);
    searchParams = getPriceQueryParams(searchParams, 'max', max);
 
    const path = window.location.pathname + "?" + searchParams.toString();
    navigate(path);
  };

  const handleClick = (checkbox) => {
    const checkboxes = document.getElementsByName(checkbox.name);

    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });

    if (checkbox.checked === false) {
      if (searchParams.has(checkbox.name)) {
        searchParams.delete(checkbox.name);
        const path = window.location.pathname + "?" + searchParams.toString();
        navigate(path);
      }
    } else {
      if (searchParams.has(checkbox.name)) {
        searchParams.set(checkbox.name, checkbox.value);
      } else {
        searchParams.append(checkbox.name, checkbox.value);
      }

      const path = window.location.pathname + "?" + searchParams.toString();
      navigate(path);
    }
  };

  const defaultCheckHandler = (checkboxType, checkboxValue) => {
    const value = searchParams.get(checkboxType);
    if (checkboxValue === value) return true;
    return false;
  };

  return (
   <div className="filters">
      <h3>Filtrele</h3>
      <hr />
      <h5 className="filter-heading mb-3">Fiyat</h5>
      <form
        id="filter_form"
        className="px-2"
        onSubmit={handleButtonClick}
      >
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Min (₺)"
              name="min"
              value={min}
              onChange={(e) => setMin(e.target.value)}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Max (₺)"
              name="max"
              value={max}
              onChange={(e) => setMax(e.target.value)}
            />
          </div>

          <div className="col">
            <button type="submit" className="btn btn-primary">Git</button>
          </div>
        </div>
      </form>
      <hr />
      <h5 className="mb-3">Kategori</h5>

      <div className="form-check">
        {categories.map(category => (
          <div key={category._id}>
            <input
              className="form-check-input"
              type="checkbox"
              name="category"
              id={`check-${category._id}`}
              value={category._id}
              defaultChecked={defaultCheckHandler('category', category._id)}
              onClick={(e) => handleClick(e.target)}
            />
            <label className="form-check-label" htmlFor={`check-${category._id}`}>
              {category.name}
            </label>
          </div>
        ))}
      </div>
      <hr />
      <h5 className="mb-3">Puan Yüksekliği</h5>

      {[5, 4, 3, 2, 1].map((rating) => (
          <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="ratings"
          id="check7"
          value={rating}
          defaultChecked={defaultCheckHandler('ratings',rating?.toString())}
          onClick={(e) => handleClick(e.target)}
        />
        <label className="form-check-label" htmlFor="check7">
          <StarRatings
                        rating={rating}
                        starRatedColor="#ffb829"
                        numberOfStars={5}
                        name='rating'
                        starDimension="21px"
                        starSpacing="1px"
                       />
        </label>
      </div>
      ))}
    
     
    </div>
  );
};

export default Filters;
