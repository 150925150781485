import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
  shippingInfo: localStorage.getItem('shippingInfo') ? JSON.parse(localStorage.getItem('shippingInfo')) : {},
};

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      const item = action.payload;

      const isItemExist = state.cartItems.find((cartItem) => cartItem.id === item.id);

      if (isItemExist) {
        state.cartItems = state.cartItems.map((cartItem) =>
          cartItem.id === isItemExist.id
            ? { ...cartItem, quantity: item.quantity } // sadece quantity güncellenir
            : cartItem
        );
      } else {
        state.cartItems = [...state.cartItems, item];
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },

    removeCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter((i) => i.id !== action.payload);

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },

    clearCart: (state) => {
      state.cartItems = [];
      localStorage.removeItem('cartItems');
    },

    saveShippingInfo: (state, action) => {
      state.shippingInfo = action.payload;

      localStorage.setItem('shippingInfo', JSON.stringify(state.shippingInfo));
    },
  },
});

export default cartSlice.reducer;

export const { setCartItems, removeCartItem, clearCart, saveShippingInfo } = cartSlice.actions;


