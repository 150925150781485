import React, { useEffect, useState } from 'react';
import { useGetAllBrandsQuery, useDeleteBrandMutation } from '../../redux/api/brandApi';
import { toast } from 'react-hot-toast';
import Loader from './../Loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import ConfirmModal from '../ConfirmModal';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './../layout/AdminLayout';



const ListBrands = () => {
   const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);
  const [deleteBrand, {isLoading:isDeleteLoading, isSuccess,  error:deleteError}] = useDeleteBrandMutation();
 const { data, isLoading, error, refetch } = useGetAllBrandsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
 
  const navigate = useNavigate();

  

  
  useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
    if (deleteError) {
      toast.error(deleteError?.data?.message || 'Marka silinirken bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Marka başarıyla silindi.');
      refetch();
    }
  }, [error, isSuccess, navigate, deleteError, refetch]);


  const handleDeleteClick = (brandId) => {
    setBrandToDelete(brandId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (brandToDelete) {
      deleteBrand(brandToDelete);
      setShowConfirmModal(false);
      setBrandToDelete(null);
    }
  };
  

  const setBrands = () => {
    if (!data) return;
    const brands={ 
      columns: [
        {
          label: 'Marka ID',
          field: 'id',
          sort: 'asc'
        },
        {
          label: 'Marka Adı',
          field: 'name',
          sort: 'asc'
        },
        
        {
          label: 'İşlemler',
          field: 'actions',
          sort: 'asc'
        }
      ],
      rows: []
    };
   data?.brands.forEach((brand) => {
     brands.rows.push({
       id: brand._id,
       name: brand.name,
       
  
    actions: <>
      <Link to={`/admin/brands/${brand._id}`} className='btn btn-outline-primary'>
        <i className="fa fa-pencil"></i>
      </Link>
     
      <button 
        className='btn btn-outline-danger ms-2' 
        onClick={() => handleDeleteClick(brand._id)}
      >
        <i className="fa fa-trash"></i>
      </button>
    </>
  });
});

    return brands;
  };


  useEffect(() => {
    if (data) {
      setBrands();
    }
  }, [data]);

  if (isLoading) return <Loader/>;
  <MDBDataTable
  data={setBrands()}
  className="px-3"
  bordered
  striped
  hover
  responsive
  />
 
  return (

    <>
      <AdminLayout>
      <MetaData title={"Marka Listesi"} />
    <div>
      <h1 className='my-5'>({data?.brands?.length}) Marka listelendi.</h1>
      
       <MDBDataTable
        data={setBrands()}
        className="px-3"
        bordered
        striped
            hover
            responsive
      />
        </div>
        <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmDelete}
        title="Markayı Sil"
        message="Bu markayı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
        />
        </AdminLayout>
        </>
    
  );
  



};

export default ListBrands;