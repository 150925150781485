import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Loader from './../Loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import { useGetAllUsersQuery, useDeleteUserMutation } from '../../redux/api/userApi';
import ConfirmModal from '../ConfirmModal';
import AdminLayout from '../layout/AdminLayout';

const ListUsers = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const { data, isLoading, error, refetch } = useGetAllUsersQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const [deleteUser, { error: deleteError, isSuccess }] = useDeleteUserMutation();
  

  

  
  useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
    if (deleteError) {
      toast.error(deleteError?.data?.msg|| 'Kullanıcı silinirken bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Kullanıcı başarıyla silindi.');
      refetch();
    }
  }, [error, deleteError, isSuccess, refetch]);

    
  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      deleteUser(userToDelete); 
      setShowConfirmModal(false);
      setUserToDelete(null);
    }
  };

  const setUsers = () => {
    if (!data) return;
    const users={ 
      columns: [
        // {
        //   label: 'Kullanıcı ID',
        //   field: 'id',
        //   sort: 'asc'
        // },
       
        {
          label: 'Kullanıcı Adı',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'E-posta',
          field: 'email',
          sort: 'asc'
        },
        
        {
          label: 'Kayıt Tarihi',
          field: 'createdAt',
          sort: 'asc'
        },
        
        
        {
          label: 'İşlemler',
          field: 'actions',
          sort: 'asc'
        }
      ],
      rows: []
    };
   data?.users.forEach((user) => {
  users.rows.push({
   // id: user._id,
    name: user.name,
    email: user.email,
    createdAt: new Date(user.createdAt).toLocaleString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }),
      
    actions: <>
      <Link to={`/admin/users/${user?._id}`} className='btn btn-outline-primary'>
        <i className="fa fa-pencil"></i>
      </Link>
     
      <button className='btn btn-outline-danger ms-2' onClick={() => handleDeleteClick(user._id)}>
        <i className="fa fa-trash"></i>
      </button>
    </>
  });
});

    return users;
  };


  useEffect(() => {
    if (data) {
      setUsers();
    }
  }, [data]);

  if (isLoading) return <Loader/>;
  <MDBDataTable
  data={setUsers()}
  className="px-3"
  bordered
  striped
  hover
  responsive
  />
 
  return (

    <>
      <AdminLayout>
      <MetaData title={"Kullanıcı Listesi"} />
    <div>
      <h1 className='my-5'>({data?.users?.length}) Kullanıcı listelendi.</h1>
      
       <MDBDataTable
        data={setUsers()}
        className="px-3"
        bordered
        striped
        hover
        responsive
      />
    
        </div>
        <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmDelete}
        title="Kullanıcıyı Sil"
        message="Bu kullanıcıyı silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
        />
        </AdminLayout>
        </>
    
  );



};

export default ListUsers;