import { Route } from 'react-router-dom';
import ProtectedRoutes from '../auth/ProtectedRoutes';
import Dashboard from '../admin/Dashboard';
import ListProducts from '../admin/ListProducts';
import NewProduct from '../admin/NewProduct';
import UpdateProduct from '../admin/UpdateProduct';
import UploadImages from '../admin/UploadImages';
import ListOrders from '../admin/ListOrders';
import ProcessOrder from '../admin/ProcessOrder';
import ListUsers from '../admin/ListUsers';
import UpdateUser from '../admin/UpdateUser';
import ProductReviews from '../admin/ProductReviews';
import Verify from '../auth/Verifiy';
import ListCategory from '../admin/ListCategory';
import NewCategory from '../admin/NewCategory';
import UpdateCategory from '../admin/UpdateCategory';
import ListBrands from '../admin/ListBrands';
import NewBrand from '../admin/NewBrand';
import UpdateBrand from '../admin/UpdateBrand';
import IyzipayInfoManager from '../admin/IyzipayInfoManager';
import CompanyInfoForm from '../admin/CompanyInfoForm';
import ProductImportExport from '../admin/ProductImportExport';
import EmailConfigManager from '../admin/EmailConfigManager';









const AdminRoutes = () => {
  return (
    <>
      <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoutes admin={true}>
                  <Dashboard />
                </ProtectedRoutes>
              }
      />

      <Route
              path="/admin/products"
        element={
          <ProtectedRoutes admin={true}>
            <ListProducts />
          </ProtectedRoutes>
        }
      />

      <Route
              path="/admin/product/new"
        element={
          <ProtectedRoutes admin={true}>
            <NewProduct />
          </ProtectedRoutes>
        }
      />

      <Route
              path="/admin/products/:id"
        element={
          <ProtectedRoutes admin={true}>
            <UpdateProduct />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/products/:id/upload_images"
        element={
          <ProtectedRoutes admin={true}>
            <UploadImages />
          </ProtectedRoutes>
        }
        />

      <Route
        path="/admin/orders"
        element={
          <ProtectedRoutes admin={true}>
            <ListOrders />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/orders/:id"
        element={
          <ProtectedRoutes admin={true}>
            <ProcessOrder />
          </ProtectedRoutes>
        } 
      />

      <Route
        path="/admin/users"
        element={
          <ProtectedRoutes admin={true}>
            <ListUsers />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/users/:id"
        element={
          <ProtectedRoutes admin={true}>
            <UpdateUser />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/reviews"
        element={
          <ProtectedRoutes admin={true}>
            <ProductReviews />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/categories"
        element={
          <ProtectedRoutes admin={true}>
            <ListCategory />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/category/new"
        element={
          <ProtectedRoutes admin={true}>
            <NewCategory />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/user/verify-email"
        element={<Verify />}
      />

      <Route
        path="/admin/categories/:id"
        element={
          <ProtectedRoutes admin={true}>
            <UpdateCategory />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/brands"
        element={
          <ProtectedRoutes admin={true}>
            <ListBrands />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/brand/new"
        element={
          <ProtectedRoutes admin={true}>
            <NewBrand />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/brands/:id"
        element={
          <ProtectedRoutes admin={true}>
            <UpdateBrand />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/iyzipay-info"
        element={
          <ProtectedRoutes admin={true}>
            <IyzipayInfoManager />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/company-info"
        element={
          <ProtectedRoutes admin={true}>
            <CompanyInfoForm />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/product-import-export"
        element={
          <ProtectedRoutes admin={true}>
            <ProductImportExport />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/email-config"
        element={
          <ProtectedRoutes admin={true}>
            <EmailConfigManager />
          </ProtectedRoutes>
        }
      />
    </>
  );
};

export default AdminRoutes;