import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    chatNotifications: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = Array.isArray(action.payload) ? action.payload : [];
    },
    setChatNotifications: (state, action) => {
      state.chatNotifications = Array.isArray(action.payload) ? action.payload : [];
    },
    addNotification: (state, action) => {
      if (!Array.isArray(state.notifications)) {
        state.notifications = [];
      }
      state.notifications.unshift(action.payload);
    },
    addChatNotification: (state, action) => {
      if (!Array.isArray(state.chatNotifications)) {
        state.chatNotifications = [];
      }
      state.chatNotifications.unshift(action.payload);
    },
    markNotificationAsRead: (state, action) => {
      if (Array.isArray(state.notifications)) {
        const notification = state.notifications.find(n => n._id === action.payload);
        if (notification) {
          notification.isRead = true;
        }
      }
    },
    markChatNotificationAsRead: (state, action) => {
      if (Array.isArray(state.chatNotifications)) {
        const notification = state.chatNotifications.find(n => n._id === action.payload);
        if (notification) {
          notification.isRead = true;
        }
      }
    },
    removeNotification: (state, action) => {
      if (Array.isArray(state.notifications)) {
        state.notifications = state.notifications.filter(n => n._id !== action.payload);
      }
    },
    removeChatNotification: (state, action) => {
      if (Array.isArray(state.chatNotifications)) {
        state.chatNotifications = state.chatNotifications.filter(n => n._id !== action.payload);
      }
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    clearChatNotifications: (state) => {
      state.chatNotifications = [];
    },
  },
});

export const {
  setNotifications,
  setChatNotifications,
  addNotification,
  addChatNotification,
  markNotificationAsRead,
  markChatNotificationAsRead,
  removeNotification,
  removeChatNotification,
  clearNotifications,
  clearChatNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;