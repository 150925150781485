import React, { useState, useEffect } from 'react';
import UserLayout from '../layout/UserLayout';
import { useNavigate, } from 'react-router-dom';
import { useUploadUserAvatarMutation } from '../../redux/api/userApi';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import MetaData from '../layout/MetaData';


const UploadAvatar = () => {
  const {user}=useSelector((state)=>state.auth)
const [profilePic, setprofilePic] = useState('');
  const [profilePicPreview, setprofilePicPreview] = useState(
    user?.profilePic ? user?.profilePic?.url : '/images/default_avatar.jpg'
  );
  
  const navigate = useNavigate();
  const [uploadUserAvatar, { isLoading, error, isSuccess }] = useUploadUserAvatarMutation();

  useEffect(() => { 
     
      if (error)
         toast.error(error?.data?.msg || 'Bilinmeyen bir hata oluştu.');

      if (isSuccess) {
        toast.success('Profil Resmi güncellendi');
        navigate('/me/profile');
      }

    }, [error, isSuccess, navigate]);


    const submitHandler = (e) => {
  e.preventDefault();
  
  // FormData nesnesi oluştur
  const formData = new FormData();
  formData.append('image', profilePic);

  // API'ye istek gönder
  uploadUserAvatar(formData);
};

  
  const onChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setprofilePicPreview(reader.result);
    };
    reader.readAsDataURL(file);

    // profilePic'i File olarak kaydedin
    setprofilePic(file);
  }
};



  return (
    <UserLayout>
      <>
      <MetaData title={"Profil Resmi Yükle"} />
     <div className="row wrapper">
      <div className="col-10 col-lg-8">
        <form
          className="shadow rounded bg-body"
          onSubmit={submitHandler}
        >
          <h2 className="mb-4">Profil Resmi Yükle</h2>

          <div className="mb-3">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <figure className="avatar item-rtl">
                    <img
                      src={profilePicPreview}
                      className="rounded-circle"
                      alt="avatar" />
                </figure>
              </div>
              <div className="input-foam">
                <label className="form-label" htmlFor="customFile">
                  Resim Seç
                </label>
                <input
                    type="file"
                    name="profilePic"
                    className="form-control"
                    id="customFile"
                    accept="images/*"
                    onChange={onChange}
                />
              </div>
            </div>
          </div>

          <button
              id="register_button"
              type="submit"
              className="btn w-100 py-2"
              disabled={isLoading}
          >
           {isLoading ? 'Yükleniyor...' : 'Kaydet'}
          </button>
        </form>
      </div>
        </div>
      </>
    </UserLayout>
  );
};

export default UploadAvatar;