import React, { useEffect } from 'react';
import MetaData from "../layout/MetaData";
import { useGetSingleOrderQuery } from "../../redux/api/orderApi";
import { Link, useParams} from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from './../Loader';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useGetOrCreateCompanyInfoQuery } from '../../redux/api/companyInfoApi';

const Invoice = () => {
  const params=useParams();
  const { data, isLoading, error } = useGetSingleOrderQuery(params?.id);
  const order = data?.order || {};
  const { shippingInfo, orderStatus, paymentInfo,  totalAmount, orderItems } = order;
  const { data: getOrCreateCompanyInfo, isLoading: companyInfoLoading, error: companyInfoError } = useGetOrCreateCompanyInfoQuery();
  const companyInfo = getOrCreateCompanyInfo?.companyInfo || {};
  const getOrCreateCompanyInfoError = getOrCreateCompanyInfo?.error;




useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
}, [error]);
  
  const handleDownload = async () => {
  const input = document.getElementById("order_invoice");
  const canvas = await html2canvas(input, {
    scale: 2,
    useCORS: true,
    logging: true,
    width: input.offsetWidth,
    height: input.offsetHeight
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'pt',
    format: [canvas.width, canvas.height]
  });

  pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
  pdf.save(`invoice_${order?._id}.pdf`);
};

  

  if (getOrCreateCompanyInfoError) return <div>Firma bilgileri yüklenirken bir hata oluştu.</div>;
  if (isLoading || companyInfoLoading) return <Loader />;
  
  return (
    <>
      <MetaData title={"Faturanız"} />
     <div className="order-invoice my-5">
      <div className="row d-flex justify-content-center mb-5">
        <button className="btn btn-success col-md-5" onClick={handleDownload}>
          <i className="fa fa-print"></i> Sipariş Detayı İndir
        </button>
      </div>
        <div id="order_invoice" className="p-3 border border-secondary">
          <div className="invoice-content">
        <header className="clearfix">
          <div id="logo">
           {companyInfo?.invoice_logo && (
                <img src={companyInfo.invoice_logo} alt="Company Logo" />
              )}
          </div>
          <h1>Sipariş No:  {order?._id}</h1>
          <div id="company" className="clearfix">
            <div>{companyInfo?.name}</div>
            <div>
              {companyInfo?.address}
            </div>
            <div>{companyInfo?.phone}</div>
            <div>
             <Link to={`mailto:${companyInfo?.email}`}>{companyInfo?.email}</Link>
            </div>
          </div>
          <div id="project">
              <div><span>Adı Soyadı</span> {order.user?.name}</div>
            <div><span>E-Posta</span> {order.user?.email}</div>
                <div><span>Telefon</span> {order.shippingInfo?.phoneNo}</div>
                
           <div className="print-address">
  <div> <span>Adres</span> : {shippingInfo?.address}</div>
  <div>{shippingInfo?.state}, {shippingInfo?.city},{shippingInfo?.country}</div>
</div>
            <div><span>Tarih</span> {new Date(order.createdAt).toLocaleString()}</div>
            <div><span>Durumu</span> {order.status}</div>
          </div>
        </header>
        <main>
          <table className="mt-5">
            <thead>
              <tr>
                <th className="service">Ürün Kodu</th>
                <th className="desc">Ürün Adı</th>
                <th>Fiyat</th>
                <th>Adet</th>
                <th>Tutar</th>
              </tr>
            </thead>
            <tbody>
               {orderItems?.map((item) => (
                  <tr>
                    <td className="service">{item?.product}</td>
                    <td className="desc">{item?.name}</td>
                    <td className="unit">{parseFloat(item?.price).toFixed(2)}₺</td>
                    <td className="qty">{item?.quantity}</td>
                    <td className="total">{parseFloat(item?.price * item?.quantity).toFixed(2)}₺</td>
                  </tr>
                ))}

              <tr>
                <td colspan="4">
                  <b>Alt Toplam</b>
                </td>
                <td className="total">{parseFloat(order.itemsAmount).toFixed(2)}₺</td>
              </tr>

              <tr>
                <td colspan="4">
                  <b>Kdv 20%</b>
                </td>
                <td className="total">{parseFloat(order.taxAmount).toFixed(2)}₺</td>
              </tr>

              <tr>
                <td colspan="4">
                  <b>Kargo</b>
                </td>
                <td className="total">{parseFloat(order?.shippingAmount).toFixed(2)}₺</td>
              </tr>

              <tr>
                <td colspan="4" className="grand total">
                  <b>Genel Toplam</b>
                </td>
                <td className="grand total">{parseFloat(order?.totalAmount).toFixed(2)}₺</td>
              </tr>
            </tbody>
          </table>
          <div id="notices">
            <div>NOT:</div>
            <div className="notice">
              Mutlu günlerde kullanın. Esenlikle...
            </div>
          </div>
        </main>
        <footer>
          AnkaOnlinePazarlama
        </footer>
      </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;