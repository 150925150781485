import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateProductMutation, useGetSingleProductQuery } from '../../redux/api/productsApi';
import { toast } from 'react-hot-toast';
import { useGetAllCategoriesQuery } from "../../redux/api/categoriesApi";
import { useGetAllBrandsQuery } from "../../redux/api/brandApi";
import MetaData from "../layout/MetaData";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();

 const { data: categoriesData } = useGetAllCategoriesQuery();
  const { data: brandsData } = useGetAllBrandsQuery();

  const categories = categoriesData?.categories || [];
  const brands = brandsData?.brands || [];

  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: "",
    category: "",
    stock: "",
    brand: "",
    featured: false,
  });

  const { name, description, price, category: categoryId, stock, brand: brandId, featured } = product;

 const [updateProduct, { isLoading, error, isSuccess }] =
    useUpdateProductMutation();

  const { data } = useGetSingleProductQuery(params?.id);


  useEffect(() => {
  if (data?.product) {
    setProduct({
      name: data.product.name,
      description: data.product.description,
      price: data.product.price,
      category: data.product.category._id, 
      stock: data.product.stock,
      brand: data.product.brand._id, 
      featured: data.product.featured,
    });
  }

   const handleUpdate = async () => {
    if (isSuccess) {
      toast.success("Ürün güncellendi");
      navigate("/admin/products");
    }

    if (error) {
      toast.error(error?.data?.message || "Bir hata oluştu");
    }
  };

  handleUpdate(); // Mutasyon tamamlandığında veya hata oluştuğunda toast'ları göster

}, [error, isSuccess, navigate, data, updateProduct]);

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct({
      ...product,
      [name]: type === 'checkbox' ? checked : (name === 'price' || name === 'stock' ? Number(value) : value),
    });
  };

  const submitHandler = async (e) => {
  e.preventDefault();


 await updateProduct({
    id: params?.id,
    body: product,
  });
};


  return (
    <>
      <MetaData title={"Ürün Güncelle"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Ürün Güncelle</h2>
            <div className="mb-3">
              <label htmlFor="name_field" className="form-label"> Ürün Adı </label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={onChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">
                Açıklama
              </label>
              <textarea
                className="form-control"
                id="description_field"
                rows="8"
                name="description"
                value={description}
                onChange={onChange}
              ></textarea>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="price_field" className="form-label"> Fiyat </label>
                <input
                  type="text"
                  id="price_field"
                  className="form-control"
                  name="price"
                  value={price}
                  onChange={onChange}
                />
              </div>

              <div className="mb-3 col">
                <label htmlFor="stock_field" className="form-label"> Stok Adedi </label>
                <input
                  type="number"
                  id="stock_field"
                  className="form-control"
                  name="stock"
                  value={stock}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="category_field" className="form-label"> Kategori </label>
                <select className="form-select" id="category_field" name="category" value={categoryId}
                  onChange={onChange}>
                  <option value="">Kategori Seçiniz</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3 col">
                <label htmlFor="brand_field" className="form-label"> Marka </label>
                <select className="form-select" id="brand_field" name="brand" value={brandId}
                  onChange={onChange}>
                  <option value="">Marka Seçiniz</option>
                  {brands.map((brand) => (
                    <option key={brand._id} value={brand._id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="featured_field"
                  name="featured"
                  checked={featured}
                  onChange={onChange}
                />
                <label className="form-check-label" htmlFor="featured_field">
                  Öne Çıkan Ürün
                </label>
              </div>
            </div>
            
            <button type="submit" className="btn w-100 py-2" disabled={isLoading}>
              {isLoading ? "Güncelleniyor..." : "Ürünü Güncelle"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateProduct;
