export const getPriceQueryParams = (searchParams, key, value) => {
  const hasValueInParam = searchParams.has(key);

  if (value && hasValueInParam) {
    searchParams.set(key, value);
  } else if (value) {
    searchParams.append(key, value);
  } else if (hasValueInParam) {
    searchParams.delete(key);
  }

  return searchParams;

 }
  
export const calculateOrdersConst = (cartItems) => {
  const itemPrice = cartItems?.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const shippingPrice = itemPrice > 1000 ? 0 : 150;
  const taxPrice = Math.round(0.20 * itemPrice);
  const totalPrice = (itemPrice + shippingPrice + taxPrice).toFixed(2);

  return {
    itemPrice: itemPrice.toFixed(2),
    shippingPrice: shippingPrice.toFixed(2),
    taxPrice: taxPrice.toFixed(2),
    totalPrice
  };
}
