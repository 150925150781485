import React, { useState, useEffect } from 'react';
import { useUpdateUserPasswordMutation } from '../../redux/api/userApi';
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import UserLayout from './../layout/UserLayout';
import MetaData from '../layout/MetaData';

const UpdatePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [updateUserPassword, { isLoading, error, isSuccess }] = useUpdateUserPasswordMutation();
  const navigate = useNavigate();
  

 const submitHandler = async (e) => {
  e.preventDefault();
  if (!oldPassword || !newPassword) {
    toast.error('Lütfen tüm alanları doldurun');
    return;
  }
  const passwordData = { oldPassword, newPassword };
  try {
    const result = await updateUserPassword(passwordData).unwrap();
    console.log('Şifre güncelleme yanıtı:', result);
    toast.success('Şifreniz başarıyla güncellendi.');
    setOldPassword('');
    setNewPassword('');
    navigate("/me/profile");
  } catch (err) {
    console.error('Şifre güncelleme hatası:', err);
    toast.error(err.data?.msg || 'Şifre güncellenirken bir hata oluştu');
  }
};



  useEffect(() => {
    
    if (error) {
      toast.error(error?.data?.msg || 'Bilinmeyen bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Sifreniz basariyla guncellendi.');
       setOldPassword('');
       setNewPassword('');
       navigate("/me/profile");
    }
   
  }, [error, isSuccess, navigate]);

  return (
    <UserLayout>
      <>
      <MetaData title={"Şifre güncelle"} />
     <div className="row wrapper">
      <div className="col-10 col-lg-8">
        <form className="shadow rounded bg-body" onSubmit={submitHandler}>
          <h2 className="mb-4">Şifre Güncelle</h2>
          <div className="mb-3">
            <label htmlFor="old_password_field" className="form-label">
              Eski Şifre
            </label>
            <input
              type="password"
              id="old_password_field"
              className="form-control"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="new_password_field" className="form-label">
              Yeni Şifre
            </label>
            <input
              type="password"
              id="new_password_field"
              className="form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <button type="submit" className="btn update-btn w-100" disabled={isLoading}>
            {isLoading ? 'Guncelleniyor...' : 'Güncelle'}
          </button>
        </form>
      </div>
        </div>
      </>
      </UserLayout>
  );
};

export default UpdatePassword;
