import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import { useGetSingleOrderQuery, useUpdateOrderMutation } from '../../redux/api/orderApi';
import { useNavigate } from 'react-router-dom';

const ProcessOrder = () => {
 const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const params = useParams();
  const { data, refetch } = useGetSingleOrderQuery(params?.id);
  const order = data?.order || {};

  const [updateOrder, { error, isSuccess }] = useUpdateOrderMutation();

  const {
    shippingInfo,
    orderItems,
    paymentInfo
  } = order;

 useEffect(() => {
    if (error) {
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
    if (isSuccess) {
      toast.success('Sipariş durumu güncellendi.');
      refetch(); // Siparişi yeniden getir
    }
  }, [error, isSuccess, refetch]);

  const updateOrderHandler = async (id) => {
    const data = { status };
    await updateOrder({ id, body: data });
    setStatus(""); // Durum seçimini sıfırla
  };

   const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya geri dön
  };



  
  return (
    <>
      <MetaData title="Sipariş Detayı" />
    <div className="row d-flex justify-content-around">
      <div class="col-12 col-lg-8 order-details">
        <h3 class="mt-5 mb-4">Sipariş Detayları</h3>

        <table class="table table-striped table-bordered">
          <tbody>
            <tr>
              <th scope="row">ID</th>
              <td>{order._id}</td>
            </tr>
            <tr>
              <th scope="row">Sipariş Durumu</th>
              <td className={String(order.status) === "teslim edildi" ? "text-success" : "text-danger"}>
                <b>{order.status}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <h3 class="mt-5 mb-4">Sevkiyat Bilgileri</h3>
        <table class="table table-striped table-bordered">
           <tbody>
            <tr>
              <th scope="row">Adı-Soyadı</th>
              <td>{order.user?.name}</td>
            </tr>
            <tr>
              <th scope="row">Telefon</th>
              <td>{shippingInfo?.phoneNo}</td>
            </tr>
            <tr>
              <th scope="row">Adres</th>
              <td>{`${shippingInfo?.address}, ${shippingInfo?.state}, ${shippingInfo?.city},  ${shippingInfo?.country}`}</td>
            </tr>
          </tbody>
        </table>

        <h3 class="mt-5 mb-4">Ödeme Bilgileri</h3>
        <table class="table table-striped table-bordered">
          <tbody>
            <tr>
              <th scope="row">Ödeme Durumu</th>
              <td className="greenColor">
                <b>{paymentInfo?.status}</b>
              </td>
            </tr>
            <tr>
              <th scope="row">Ödeme Şekli</th>
              <td>{order.paymentMethod}</td>
            </tr>
            {/* <tr>
              <th scope="row">Stripe ID</th>
              <td>stripe-id</td>
            </tr> */}
            <tr>
            <th scope="row">Ödeme Tutarı</th>
             <td> {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(order.totalAmount)}</td>
            </tr>
          </tbody>
        </table>

        <h3 class="mt-5 my-4">Sipariş Kalemleri:</h3>

        <hr />
        <div class="cart-item my-1">
         {orderItems?.map((item) => (
              <div className="row my-5">
                <div className="col-4 col-lg-2">
                  <img
                    src={item?.image}
                    alt={item?.name}
                    height="45"
                    width="65"
                  />
                </div>

                <div className="col-5 col-lg-5">
                  <Link to={`/product/${item?.product}`}>{item?.name}</Link>
                </div>

                <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                  <td>{parseFloat(item?.price).toFixed(2)}₺</td>
                </div>

                <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                  <p>{item?.quantity} Adet</p>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>

      <div class="col-12 col-lg-3 mt-5">
        <h4 class="my-4">Durumu</h4>

        <div class="mb-3">
        
            <select class="form-select" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="hazırlanıyor">hazırlanıyor</option>
              <option value="kargolandı">kargolandı</option>
              <option value="teslim edildi">teslim edildi</option>
            </select>
              <button type="submit" class="btn btn-primary w-100 mt-3" onClick={() => updateOrderHandler(order?._id)}>
               Durumu Güncelle
              </button>
          
        </div>

    

        <h4 class="mt-5 mb-3">Sipariş Dokümanı</h4>
        <Link to={`/me/invoice/${order._id}`} class="btn btn-success w-100">
          <i class="fa fa-print"></i> Belge Oluştur
        </Link>
      </div>
      <button className='btn btn-outline-primary' onClick={handleGoBack}>Geri Dön</button> 
    </div>
</>
  );
};

export default ProcessOrder;