import React, { useEffect, useState } from 'react';
import MetaData from "../layout/MetaData";
import CheckOutSteps from './CheckOutSteps';
import { useSelector, useDispatch } from 'react-redux';
import { orderApi } from '../../redux/api/orderApi';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { calculateOrdersConst } from '../../helpers/helpers';
import { clearCart } from '../../redux/features/cartSlice';

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardDetails, setCardDetails] = useState({
    cardHolderName: '',
    cardNumber: '',
    expireMonth: '',
    expireYear: '',
    cvc: ''
  });
  
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [createOrder, { isLoading, isSuccess, error }] = orderApi.useCreateOrderMutation();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.msg || 'Bir hata oluştu');
    }

    if (isSuccess) {
      toast.success('Siparişiniz Alındı');
      dispatch(clearCart());
      navigate('/order/success', { state: { paymentMethod } });
    }
  }, [error, isSuccess, navigate, dispatch, paymentMethod]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const { itemPrice, shippingPrice, taxPrice, totalPrice } = calculateOrdersConst(cartItems);
    
    const orderData = {
      shippingInfo,
      orderItems: cartItems,
      paymentMethod,
      itemsAmount: parseFloat(itemPrice),
      taxAmount: parseFloat(taxPrice),
      shippingAmount: parseFloat(shippingPrice),
      totalAmount: parseFloat(totalPrice),
      user: user
    };

    if (paymentMethod === 'Card') {
      orderData.paymentInfo = { ...cardDetails };
    }

    try {
      await createOrder(orderData).unwrap();
    } catch (err) {
      toast.error(err.data?.message || 'Sipariş oluşturulurken bir hata oluştu');
    }
  };

  const handleCardInputChange = (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  return (
    <>
      <MetaData title="Ödeme Seçimi" />
      <CheckOutSteps shipping confirmOrder payment />
      <div className="row wrapper">
        <div className="col-10 col-lg-5">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Ödeme Şekli Seç</h2>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="payment_mode"
                id="codradio"
                value="COD"
                onChange={(e) => setPaymentMethod('COD')}
              />
              <label className="form-check-label" htmlFor="codradio">
                Banka Transferi
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="payment_mode"
                id="cardradio"
                value="Card"
                onChange={(e) => setPaymentMethod('Card')}
              />
              <label className="form-check-label" htmlFor="cardradio">
                Kart - VISA, MasterCard
              </label>
            </div>

            {paymentMethod === 'Card' && (
              <>
                <input
                  type="text"
                  name="cardHolderName"
                  placeholder="Kart Sahibi Adı"
                  value={cardDetails.cardHolderName}
                  onChange={handleCardInputChange}
                  className="form-control my-3"
                />
                <input
                  type="text"
                  name="cardNumber"
                  placeholder="Kart Numarası"
                  value={cardDetails.cardNumber}
                  onChange={handleCardInputChange}
                  className="form-control my-3"
                />
                <input
                  type="text"
                  name="expireMonth"
                  placeholder="Son Kullanım Ay (MM)"
                  value={cardDetails.expireMonth}
                  onChange={handleCardInputChange}
                  className="form-control my-3"
                />
                <input
                  type="text"
                  name="expireYear"
                  placeholder="Son Kullanım Yıl (YYYY)"
                  value={cardDetails.expireYear}
                  onChange={handleCardInputChange}
                  className="form-control my-3"
                />
                <input
                  type="text"
                  name="cvc"
                  placeholder="CVC"
                  value={cardDetails.cvc}
                  onChange={handleCardInputChange}
                  className="form-control my-3"
                />
              </>
            )}

            <button id="shipping_btn" type="submit" className="btn py-2 w-100" disabled={isLoading || !paymentMethod}>
              {isLoading ? 'Sipariş Tamamlanıyor...' : 'Siparişi Tamamla'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;