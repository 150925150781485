import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import MetaData from '../layout/MetaData';

const OrderSuccess = () => {
  const location = useLocation();
  const paymentMethod = location.state?.paymentMethod;

  return (
    <>
      <MetaData title={'Sipariş Başarılı'} />
      <div className="container container-fluid">
        <div className="row justify-content-center">
          <div className="col-6 mt-5 text-center">
            <img
              className="my-5 img-fluid d-block mx-auto"
              src="/images/order_success.png"
              alt="Order Success"
              width="200"
              height="200"
            />

            <h2>Siparişiniz başarıyla alındı.</h2>

            <p className="mt-4">
              Sayın Müşterimiz siparişiniz başarıyla alındı,
              Alışverişinizde bizi tercih ettiğiniz için çok teşekkür eder, aldığınız üründen memnun
              kalmanızı ve uzun süreler, sağlıklı günlerde kullanmanızı temenni ederiz.
            </p>
            <p>
              Siparişiniz en kısa sürede hazırlanıp tarafınıza ulaştırılacaktır. Profil menünüzden siparişler başlığına tıklayarak sipariş sayfanızı açabilir, siparişinizin durumunu ve detaylarını
              buradan takip edebilirsiniz.
            </p>
            {paymentMethod === 'COD' && (
              <p>
                Ödeme seçeneği olarak banka transferini seçtiğiniz için
                İletişim menüsünden banka hesap numaramızı alıp, sipariş numarasını açıklama hanesine yazarak transferi gerçekleştirdikten sonra siparişiniz işleme alınacaktır.
              </p>
            )}
            <p>Saygılarımızla,</p>

            <div className="mt-5">
              <Link to="/me/orders" className="btn btn-primary me-3">
                Siparişlerim
              </Link>
              <Link to="/" className="btn btn-secondary">
                Ana Sayfa
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;