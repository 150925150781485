import React, { useEffect } from 'react';
import { useGetCurrentUserOrdersQuery } from '../../redux/api/orderApi';
import { toast } from 'react-hot-toast';
import Loader from './../Loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from "../layout/MetaData";

const MyOrders = () => {
  const { data, isLoading, error } = useGetCurrentUserOrdersQuery();
 
  
  useEffect(() => {
    if (error) {
  
      toast.error(error?.data?.message || 'Bilinmeyen bir hata oluştu.');
    }
  }, [error]);

  const setOrders = () => {
  if (!data) return;
  const orders = {
    columns: [
      {
        label: 'Sipariş No',
        field: 'id',
        sort: 'asc'
      },
      {
        label: 'Tarih',
        field: 'createdAt',
        sort: 'asc'
      },
      {
        label: 'Sipariş Durumu',
        field: 'status',
        sort: 'asc'
      },
      {
        label: 'Toplam',
        field: 'totalAmount',
        sort: 'asc'
      },
      {
        label: 'Ödeme Şekli',
        field: 'paymentMethod',
        sort: 'asc'
      },
      {
        label: 'Tahsilat Durumu',
        field: 'paymentStatus',
        sort: 'asc'
      },
      {
        label: 'İşlemler',
        field: 'actions',
        sort: 'asc'
      }
    ],
    rows: []
  };
  data?.orders.forEach((order) => {
    orders.rows.push({
      id: order._id,
      createdAt: new Date(order.createdAt).toLocaleDateString(),
      status: order.status,
      totalAmount: `${parseFloat(order.totalAmount).toFixed(2)}₺`,
      paymentMethod: order.paymentMethod.toUpperCase(),
      paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
      actions: (
        <>
          <Link to={`/me/order/${order._id}`} className='btn btn-primary btn-sm me-2'>
            <i className="fa fa-eye"></i>
          </Link>
          <Link to={`/me/invoice/${order._id}`} className='btn btn-success btn-sm'>
            <i className="fa fa-print"></i>
          </Link>
        </>
      )
    });
  });
  return orders;
};
    


  useEffect(() => {
    if (data) {
      setOrders();
    }
  }, [data]);

  if (isLoading) return <Loader/>;
  
  return (
     <>
      <MetaData title={"Siparişleriniz"} />
    <div>
      <h1 className='my-5'>{data?.orders?.length} Sipariş</h1>
      
      <MDBDataTable 
        data={setOrders()}
        className='px-3'
        bordered
        striped
          hover
          responsive
      />
      
      </div>
     </>
  );



};

export default MyOrders;