import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout"
import {
  useGetSingleUserQuery,
  useUpdateUserMutation,
} from "../../redux/api/userApi";

const UpdateUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const { data } = useGetSingleUserQuery(params?.id);

   const [updateUser, { isLoading }] = useUpdateUserMutation();

  useEffect(() => {
    if (data?.user) {
      setName(data.user.name);
      setEmail(data.user.email);
      setRole(data.user.role);
    }
  }, [data]);

  const submitHandler = async (e) => {
  e.preventDefault();

  const userData = {
    name,
    email,
    role,
  };

  try {
   // console.log("Gönderilen veri:", userData); // Kontrol için eklendi
    const result = await updateUser({ id: params?.id, body: userData }).unwrap();
    if (result.success) {
      toast.success(result.message);
      navigate("/admin/users");
    } else {
      toast.error("Güncelleme başarısız oldu");
    }
  } catch (err) {
    toast.error(err?.data?.message || "Güncelleme sırasında bir hata oluştu");
    console.error("Güncelleme hatası:", err);
  }
};

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <AdminLayout>
      <MetaData title={"Kullanıcı Güncelle"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-8">
          <form className="shadow-lg" onSubmit={submitHandler}>
            <h2 className="mb-4">Kullanıcı Güncelle</h2>


            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">
                Kullanıcı Adı
              </label>
              <input
                type="name"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email_field" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email_field"
                className="form-control"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="role_field" className="form-label">
                Rol
              </label>
              <select
                id="role_field"
                className="form-select"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="user">user</option>
                <option value="admin">admin</option>
              </select>
            </div>

            <button type="submit" className="btn update-btn w-100 py-2" disabled={isLoading}>
              {isLoading ? "Güncelleniyor..." : "Güncelle"}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default UpdateUser;
