import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const brandApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
  }),
  endpoints: builder => ({
    getAllBrands: builder.query({
      query: () => '/brands',
    }),

     getBrand: builder.query({
      query: (id) => ({
        url: `/brands/${id}`,
      }),
     }),
      createBrand: builder.mutation({
        query: (brand) => ({
          url: '/brands',
          method: 'POST',
          body: brand,
        }),
      }),
      
         updateBrand: builder.mutation({
          query: ({ id, body }) => ({
        url: `/brands/${id}`,
    method: 'PATCH',
    body: body,
  }),
}),

      deleteBrand: builder.mutation({
        query: (id) => ({
          url: `/brands/${id}`,
          method: 'DELETE',
        }),
      }),
      
      
  }),
});

   

  

export const {useGetAllBrandsQuery, useGetBrandQuery, useCreateBrandMutation, useUpdateBrandMutation, useDeleteBrandMutation} = brandApi;