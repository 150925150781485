import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useVerifyEmailMutation } from '../../redux/api/authApi';
import { toast } from 'react-hot-toast';

const Verify = () => {
  const [verifyEmail] = useVerifyEmailMutation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const verificationToken = searchParams.get('token');

    if (email && verificationToken) {
      const verifyToken = async () => {
        try {
          const result = await verifyEmail({ email, verificationToken });
          if (result.data) {
            toast.success('E-posta adresiniz başarıyla doğrulandı.');
            navigate('/login');
          } else {
            toast.error('E-posta doğrulama başarısız oldu.');
          }
        } catch (error) {
          toast.error(error?.data?.msg || 'E-posta doğrulama sırasında bir hata oluştu.');
        }
      };

      verifyToken();
    } else {
      toast.error('Geçersiz doğrulama bağlantısı.');
      navigate('/');
    }
  }, [verifyEmail, navigate, location]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          <h2>E-posta Doğrulama</h2>
          <p>E-posta adresiniz doğrulanıyor, lütfen bekleyin...</p>
        </div>
      </div>
    </div>
  );
};

export default Verify;